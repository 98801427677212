import {
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListSubheader,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Switch,
    TextField,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { mapRSStatus } from '../../OrdersTable';
import {
    OrderActionsType,
    OrderActionsTypeValue,
    OrderCustomerType,
    OrderPayingMethod,
    OrderShippingMethod,
    OrderStatuses,
} from '../../enums';
import { OrderAuth, OrderForm } from '../createOrderForm';
import { AuthsFormController } from './AuthsFormController';

interface OrdersActionsInput {
    auths: Array<OrderAuth>;
    customerType: string;
    actionType?: OrderActionsTypeValue;
    control: Control<OrderForm>;
    errors: FieldErrors<OrderForm>;
    isStatusDisabled?: boolean;
    disableUserInput?: boolean;
    onAuthChange: (id: string) => void;
}

const OrdersActions = ({
    auths,
    customerType,
    actionType = OrderActionsType.CREATE,
    control,
    errors,
    isStatusDisabled,
    disableUserInput,
    onAuthChange,
}: OrdersActionsInput) => {
    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component={Card}
            subheader={<ListSubheader>Akcije</ListSubheader>}
        >
            {actionType === OrderActionsType.EDIT ? (
                <>
                    <Divider>Status</Divider>
                    <ListItem>
                        <Controller
                            name='status'
                            control={control}
                            defaultValue={OrderStatuses.INITIALIZED}
                            disabled={isStatusDisabled || disableUserInput}
                            render={({ field }) => (
                                <FormControl {...field} fullWidth>
                                    <InputLabel id='status'>Status</InputLabel>
                                    <Select {...field} label='status' labelId='status'>
                                        {Object.values(OrderStatuses).map((status) => {
                                            if (
                                                status === OrderStatuses.TEST ||
                                                status === OrderStatuses.DELETED
                                            ) {
                                                return;
                                            }
                                            return (
                                                <MenuItem key={status} value={status}>
                                                    {mapRSStatus(status)}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </ListItem>
                </>
            ) : null}
            <Divider>Nacin placanja</Divider>
            <ListItem>
                <Controller
                    name='payingMethod'
                    control={control}
                    defaultValue={OrderPayingMethod.STANDARD}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={OrderPayingMethod.STANDARD}
                                control={<Radio />}
                                label='Gotovinski'
                            />
                            <FormControlLabel
                                value={OrderPayingMethod.LEGAL}
                                control={<Radio />}
                                label='Virmanski'
                            />
                        </RadioGroup>
                    )}
                />
            </ListItem>
            <Divider>Nacin isporuke</Divider>
            <ListItem>
                <Controller
                    name='shippingMethod'
                    control={control}
                    defaultValue={OrderShippingMethod.STANDARD}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={OrderShippingMethod.STANDARD}
                                control={<Radio />}
                                label='U radnji'
                            />
                            <FormControlLabel
                                value={OrderShippingMethod.SHIPPING}
                                control={<Radio />}
                                label='Slanje'
                            />
                        </RadioGroup>
                    )}
                />
            </ListItem>
            <Divider>Kupac</Divider>
            <ListItem>
                <Controller
                    name='customerType'
                    control={control}
                    defaultValue={OrderCustomerType.NEW}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel
                                value={OrderCustomerType.NEW}
                                control={<Radio />}
                                label='Gost'
                                disabled={disableUserInput}
                            />
                            <FormControlLabel
                                value={OrderCustomerType.REGULAR}
                                control={<Radio />}
                                label='Stalni'
                                disabled={disableUserInput}
                            />
                        </RadioGroup>
                    )}
                />
            </ListItem>
            {auths && auths.length && customerType === OrderCustomerType.REGULAR ? (
                <ListItem>
                    <AuthsFormController
                        control={control}
                        auths={auths}
                        errors={errors}
                        disabled={disableUserInput}
                        onAuthChange={onAuthChange}
                    />
                </ListItem>
            ) : (
                <List>
                    <ListItem>
                        <Controller
                            name='customerName'
                            control={control}
                            defaultValue=''
                            disabled={disableUserInput}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant='standard'
                                    label='Ime i prezime'
                                    fullWidth
                                    error={!!errors.customerName}
                                    helperText={errors.customerName?.message}
                                />
                            )}
                        />
                    </ListItem>
                    <ListItem>
                        <Controller
                            name='customerEmail'
                            control={control}
                            defaultValue=''
                            disabled={disableUserInput}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    variant='standard'
                                    label='Email'
                                    fullWidth
                                    error={!!errors.customerEmail}
                                    helperText={errors.customerEmail?.message}
                                />
                            )}
                        />
                    </ListItem>
                </List>
            )}
            <Divider>Ostalo</Divider>
            <Stack direction='row' justifyContent='space-between'>
                <ListItem disableGutters>
                    <Controller
                        name='isPaid'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                checked={value}
                                onChange={onChange}
                                control={<Switch />}
                                labelPlacement='start'
                                label='Placeno'
                            />
                        )}
                    />
                </ListItem>
                <ListItem disableGutters>
                    <Controller
                        name='isAssemblyRequired'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                checked={value}
                                onChange={onChange}
                                control={<Switch />}
                                labelPlacement='start'
                                label='Montaza'
                            />
                        )}
                    />
                </ListItem>
            </Stack>
        </List>
    );
};

export default OrdersActions;
