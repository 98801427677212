import { AxiosInstance, isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { getCategoryDefaultUnits } from '../../../app/entities/category';
import { APIOrder } from '../../../app/entities/order';
import { APIOrderLine } from '../../../app/entities/orderLine';
import { APIProduct, getCategory } from '../../../app/entities/product';
import { removeFalsyValueFromObject } from '../../../app/helpers/objectManipulation';
import {
    CreateOrderAPIInput,
    OrderForm,
    OrderLine,
    OrderLineAPIInput,
    UpdateOrderAPIInput,
} from '../create/createOrderForm';
import {
    OrderCustomerType,
    OrderPayingMethod,
    OrderShippingMethod,
    OrderStatuses,
    OrderTypes,
} from '../enums';
import { printDocument } from '../printDocument';

export const mapCreateOrderInput = (orderLineList: Array<OrderLine>, formData: OrderForm) => {
    const inputOrders: Array<OrderLineAPIInput> = buildOrderLineAPIInput(orderLineList);
    const createOrderInput: CreateOrderAPIInput = {
        isPaid: formData.isPaid || false,
        isAssemblyRequired: formData.isAssemblyRequired || false,
        payingMethod: formData.payingMethod || OrderPayingMethod.STANDARD,
        shippingMethod: formData.shippingMethod || OrderShippingMethod.STANDARD,
        customerType: formData.customerType || OrderCustomerType.NEW,
        orderType: formData.orderType || OrderTypes.LOCAL,
        orders: inputOrders,
        status: OrderStatuses.INITIALIZED,
    };
    if (createOrderInput.customerType === OrderCustomerType.NEW) {
        createOrderInput.customerName = formData.customerName;
        createOrderInput.customerEmail = formData.customerEmail;
    } else {
        createOrderInput.customerId = formData.customer?._id;
    }
    if (createOrderInput.payingMethod === OrderPayingMethod.LEGAL) {
        createOrderInput.companyName = formData.companyName;
        createOrderInput.companyNumber = formData.companyNumber;
        createOrderInput.companyTaxNumber = formData.companyTaxNumber;
        createOrderInput.companyAddress = formData.companyAddress;
        createOrderInput.companyTown = formData.companyTown;
        createOrderInput.companyZip = formData.companyZip;
        createOrderInput.companyPhone = formData.companyPhone;
    }
    if (createOrderInput.shippingMethod === OrderShippingMethod.SHIPPING) {
        createOrderInput.name = formData.name;
        createOrderInput.country = formData.country || 'Srbija';
        createOrderInput.town = formData.town;
        createOrderInput.address = formData.address;
        createOrderInput.zip = formData.zip;
        createOrderInput.phone = formData.phone;
        createOrderInput.email = formData.email;
    }
    return removeFalsyValueFromObject(createOrderInput);
};

interface MapToUpdateOrderInput {
    order: APIOrder;
    orderLineList: Array<OrderLine>;
    removedOrderLineList: Array<string>;
    isOrderLineListDirty: boolean;
    formData: Partial<OrderForm>;
}

export const mapToUpdateOrderInput = ({
    order,
    orderLineList,
    removedOrderLineList,
    isOrderLineListDirty,
    formData,
}: MapToUpdateOrderInput) => {
    if (!formData._id) {
        return;
    }
    const updateOrderInput: UpdateOrderAPIInput = {
        id: formData._id,
        isPaid: formData.isPaid ?? order.isPaid ?? false,
        isAssemblyRequired: formData.isAssemblyRequired ?? order.isAssemblyRequired ?? false,
        shouldRemoveFromBalance:
            order.isRemovedFromBalance === false ? formData.shouldRemoveFromBalance : false,
        payingMethod: formData.payingMethod || order.payingMethod || OrderPayingMethod.STANDARD,
        shippingMethod:
            formData.shippingMethod || order.shippingMethod || OrderShippingMethod.STANDARD,
        customerType: formData.customerType || order.customerType || OrderCustomerType.NEW,
        orderType: formData.orderType || order.orderType || OrderTypes.LOCAL,
        status: formData.status || order.status || OrderStatuses.INITIALIZED,
    };
    if (isOrderLineListDirty) {
        updateOrderInput.orders = buildOrderLineAPIInput(orderLineList);
    }
    if (updateOrderInput.customerType === OrderCustomerType.NEW) {
        updateOrderInput.customerName = formData.customerName || order.customerName;
        updateOrderInput.customerEmail = formData.customerEmail || order.customerEmail;
    } else {
        updateOrderInput.customerId = formData.customer?._id || order.customer?._id;
    }
    if (updateOrderInput.payingMethod === OrderPayingMethod.LEGAL) {
        updateOrderInput.companyName = formData.companyName || order.companyName;
        updateOrderInput.companyNumber = formData.companyNumber || order.companyNumber;
        updateOrderInput.companyTaxNumber = formData.companyTaxNumber || order.companyTaxNumber;
        updateOrderInput.companyAddress = formData.companyAddress || order.companyAddress;
        updateOrderInput.companyTown = formData.companyTown || order.companyTown;
        updateOrderInput.companyZip = formData.companyZip || order.companyZip;
        updateOrderInput.companyPhone = formData.companyPhone || order.companyPhone;
    }
    if (updateOrderInput.shippingMethod === OrderShippingMethod.SHIPPING) {
        updateOrderInput.name = formData.name || order.name;
        updateOrderInput.country = formData.country || order.country || 'Srbija';
        updateOrderInput.town = formData.town || order.town;
        updateOrderInput.address = formData.address || order.address;
        updateOrderInput.zip = formData.zip || order.zip;
        updateOrderInput.phone = formData.phone || order.phone;
        updateOrderInput.email = formData.email || order.email;
    }
    if (removedOrderLineList && removedOrderLineList.length > 0) {
        updateOrderInput.removedOrderLineList = removedOrderLineList;
    }
    return removeFalsyValueFromObject(updateOrderInput);
};

const buildOrderLineAPIInput = (orderLineList: Array<OrderLine>): Array<OrderLineAPIInput> => {
    return orderLineList.map((orderLine) => {
        const newOrderLine: OrderLineAPIInput = {
            productId: orderLine.productId,
            desiredQuantity: orderLine.desiredQuantity,
            price: orderLine.price,
            pricePerOrderLine: calculateTotalPrice(orderLine),
            discountPercentage: orderLine.discountPercentage,
        };
        if (orderLine.orderLineId) {
            newOrderLine.orderLineId = orderLine.orderLineId;
        }
        return newOrderLine;
    });
};

export function calculateTotalPrice(order: OrderLine) {
    const price = order.price / getCategoryDefaultUnits(order.category?.slug);
    return price * order.desiredQuantity;
}

export const mapToOrderLineList = (apiOrderLinesList: Array<APIOrderLine>): Array<OrderLine> => {
    const orderLineList: Array<OrderLine> = [];
    apiOrderLinesList.forEach((apiOrderLine) => {
        const category = getCategory(apiOrderLine.product.category);
        if (!category) {
            return console.error('Category not found');
        }
        const orderLine: OrderLine = {
            productId: apiOrderLine.product._id,
            uniqueId: apiOrderLine.product.uniqueId,
            title: apiOrderLine.product.title,
            quantity: apiOrderLine.product.quantity,
            originalPrice: apiOrderLine.product.price,
            price: apiOrderLine.price,
            optionalPrice: apiOrderLine.product.optionalPrice,
            priceUnit: apiOrderLine.product.priceUnit,
            status: apiOrderLine.product.status,
            images: apiOrderLine.product.images,
            desiredQuantity: apiOrderLine.quantity,
            discountPercentage: apiOrderLine.discount,
            category: category,
        };
        if (apiOrderLine._id) {
            orderLine.orderLineId = apiOrderLine._id;
        }
        orderLineList.push(orderLine);
    });
    return orderLineList;
};

// TODO: revisit logic for category
export const mapProductToOrderLine = (apiProduct: APIProduct): OrderLine | void => {
    const category = getCategory(apiProduct.category);
    if (!category) {
        return console.error('Category not found');
    }
    const orderLine: OrderLine = {
        productId: apiProduct._id,
        title: apiProduct.title,
        uniqueId: apiProduct.uniqueId,
        quantity: apiProduct.quantity,
        originalPrice: apiProduct.price,
        price: apiProduct.price,
        priceUnit: apiProduct.priceUnit,
        images: apiProduct.images,
        category: category,
        discountPercentage: 0,
        desiredQuantity: 1,
    };
    if (category) {
        orderLine.desiredQuantity = getCategoryDefaultUnits(category.slug);
    }
    return orderLine;
};

// TODO: validate data from form
export const validateCreateOrderInput = (orders: Array<OrderLine>, data: OrderForm) => {
    if (!orders || orders.length <= 0) {
        const errorMessage = 'Niste dodali ni jedan proizvod';
        enqueueSnackbar(errorMessage, { variant: 'error' });
        throw new Error(errorMessage);
    }
    const quantityMoreThanAvailableProduct = orders.find(
        (order) => order.quantity < order.desiredQuantity,
    );
    if (quantityMoreThanAvailableProduct) {
        const errorMessage = `Proizvod '${quantityMoreThanAvailableProduct.title}' nema dovoljno trazene kolicine na stanju`;
        enqueueSnackbar(errorMessage, { variant: 'error' });
        throw new RangeError(errorMessage);
    }
    const zeroQuantityCheck = orders.find((order) => order.desiredQuantity <= 0);
    if (zeroQuantityCheck) {
        const errorMessage = `'${zeroQuantityCheck.title}' kolicina mora biti veca od 0`;
        enqueueSnackbar(errorMessage, { variant: 'error' });
        throw new RangeError(errorMessage);
    }
};

export const createOrderRequest = async (
    orders: Array<OrderLine>,
    data: OrderForm,
    axiosPrivate: AxiosInstance,
) => {
    const createOrderInput = mapCreateOrderInput(orders, data);
    const abortController = new AbortController();
    try {
        const response = await axiosPrivate.post('/v2/orders', createOrderInput, {
            signal: abortController.signal,
        });

        if (response && response.data.order) {
            enqueueSnackbar('Otpremnica je uspesno dodata.', { variant: 'success' });
            return response.data.order;
        }
    } catch (error) {
        if (isAxiosError(error)) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            return console.error(error?.response?.data?.message);
        }
        console.error(error);
    }
};

export interface UpdateOrderRequestInput extends MapToUpdateOrderInput {
    isOrderLineListDirty: boolean;
    axiosPrivate: AxiosInstance;
}

export const updateOrderRequest = async ({
    order,
    orderLineList,
    removedOrderLineList,
    isOrderLineListDirty,
    formData,
    axiosPrivate,
}: UpdateOrderRequestInput) => {
    const mapToOrderOrderInputData: MapToUpdateOrderInput = {
        order,
        orderLineList,
        removedOrderLineList,
        isOrderLineListDirty,
        formData,
    };
    const updateOrderInput = mapToUpdateOrderInput(mapToOrderOrderInputData);
    const abortController = new AbortController();
    try {
        const response = await axiosPrivate.patch('/v2/orders', updateOrderInput, {
            signal: abortController.signal,
        });

        if (response && response.data.updatedOrder) {
            enqueueSnackbar('Otpremnica je uspesno azurirana.', { variant: 'success' });
            return {
                updatedOrder: response.data.updatedOrder,
                updatedOrderLines: response.data.updatedOrderLines,
            };
        }
    } catch (error) {
        if (isAxiosError(error)) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            return console.error(error?.response?.data?.message);
        }
        console.error(error);
    }
};

export const requestDocumentPrint = (orderId: string, axiosPrivate: AxiosInstance) => {
    async function loadOrder() {
        try {
            const abortController = new AbortController();
            const response = await axiosPrivate.get(`/v2/orders/${orderId}`, {
                signal: abortController.signal,
            });

            if (response && response.data.order && response.data.orderLines) {
                printDocument(response.data.order, response.data.orderLines);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    }
    loadOrder();
};
