import { AxiosInstance, isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { CreateAuthForm } from '../forms/createAuthForm';
import { UpdateAuthForm } from '../forms/updateAuthForm';

export const createAuthRequest = async (data: CreateAuthForm, axiosPrivate: AxiosInstance) => {
    const abortController = new AbortController();
    try {
        const response = await axiosPrivate.post('/v2/auths/create-auth', data, {
            signal: abortController.signal,
        });

        if (response && response.data.auth) {
            enqueueSnackbar('Korisnik je uspesno dodat.', { variant: 'success' });
            return response.data.auth;
        }
    } catch (error) {
        if (isAxiosError(error)) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            return console.error(error?.response?.data?.message);
        }
        console.error(error);
    }
};

export const updateAuthRequest = async (
    id: string,
    userId: string,
    data: UpdateAuthForm,
    axiosPrivate: AxiosInstance,
) => {
    const requestData = { ...data, id: id, userId: userId };
    const abortController = new AbortController();
    try {
        const response = await axiosPrivate.patch('/v2/auths', requestData, {
            signal: abortController.signal,
        });

        if (response && response.data.auth) {
            enqueueSnackbar('Korisnik je uspesno azuriran.', { variant: 'success' });
            return response.data.auth;
        }
    } catch (error) {
        if (isAxiosError(error)) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            return console.error(error?.response?.data?.message);
        }
        console.error(error);
    }
};

export const deleteAuthRequest = async (id: string, axiosPrivate: AxiosInstance) => {
    const abortController = new AbortController();
    try {
        const response = await axiosPrivate.delete(`/v2/auths/${id}`, {
            signal: abortController.signal,
        });

        if (response && response.status === 204) {
            enqueueSnackbar('Korisnik je uspesno obrisan.', { variant: 'success' });
            return response.status === 204;
        }
    } catch (error) {
        if (isAxiosError(error)) {
            enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
            return console.error(error?.response?.data?.message);
        }
        console.error(error);
    }
};
