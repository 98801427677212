import { Delete, Edit, MoreVert } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import React, { RefObject } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import TablePaginationActions from '../../app/components/TablePaginationActions';
import { tableHeight } from '../../app/constants';
import { Auth, mapRSAuthRoles, mapRSAuthStatuses } from './types/user';

interface AuthsTableInput {
    auths: Array<Auth>;
    authsCount: number;
    page: number;
    tableRef: RefObject<HTMLTableElement>;
    handleChangePage: (event: unknown, newPage: number) => void;
    onDeleteAuth: (id: string) => void;
}

function AuthTable({
    auths,
    authsCount,
    page,
    tableRef,
    handleChangePage,
    onDeleteAuth,
}: AuthsTableInput) {
    const navigate = useNavigate();
    return (
        <Box>
            <TableContainer component={Paper} sx={{ maxHeight: tableHeight }}>
                <Table stickyHeader ref={tableRef} sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Ime</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Pozicija</TableCell>
                            <TableCell>Verifikovan</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Notifikacije</TableCell>
                            <TableCell>Akcije</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {auths.map((auth) => (
                            <TableRow
                                hover
                                key={auth.email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell
                                    component='th'
                                    scope='row'
                                    // NOTE: This is for sticky first column
                                    // style={{
                                    //     position: 'sticky',
                                    //     left: 0,
                                    //     background: 'rgba(255,255,255,0.9)',
                                    //     zIndex: 800,
                                    // }}
                                >
                                    {auth.name}
                                </TableCell>
                                <TableCell>{auth.email}</TableCell>
                                <TableCell>{mapRSAuthRoles(auth.role)}</TableCell>
                                <TableCell>
                                    {auth.verified ? (
                                        <CheckBoxIcon color='success' />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </TableCell>
                                <TableCell>{mapRSAuthStatuses(auth.status)}</TableCell>
                                <TableCell>
                                    {auth.pushTokenEnabled ? (
                                        <CheckBoxIcon color='success' />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <UsersActions
                                        authId={auth._id}
                                        onDeleteAuth={onDeleteAuth}
                                        navigate={navigate}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25]}
                component='div'
                count={authsCount}
                rowsPerPage={25}
                page={page}
                onPageChange={handleChangePage}
                ActionsComponent={TablePaginationActions}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

interface UsersActionsProps {
    authId: string;
    onDeleteAuth: (id: string) => void;
    navigate: NavigateFunction;
}

const UsersActions = ({ authId, onDeleteAuth, navigate }: UsersActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const options = [
        {
            icon: <Edit />,
            text: 'Izmeni korisnika',
            handleOption: () => {
                navigate(`./edit/${authId}`);
            },
            disabled: false,
        },
        // {
        //     icon: <Archive />,
        //     text: 'Arhiviraj',
        //     handleOption: () => {
        //         console.log('Arhiviraj');
        //     },
        //     disabled: true,
        // },
        {
            icon: <Delete />,
            text: 'Obrisi',
            handleOption: () => {
                onDeleteAuth(authId);
            },
            disabled: false,
        },
    ];
    return (
        <Box>
            <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.text}
                        onClick={() => {
                            handleClose();
                            option.handleOption();
                        }}
                        disabled={option.disabled}
                    >
                        <ListItemIcon>{option.icon}</ListItemIcon>
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default AuthTable;
