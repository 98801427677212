import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
} from '@mui/material';
import React from 'react';
import { UploadedPercentageComponent } from '../../app/components/UploadPercentageComponent';

interface CreateCarouselModalInput {
    openCreateCarouselModal: boolean;
    newImageName: string;
    previewImage: string;
    percentage: number;
    isImageUploaded: boolean;
    deleteImage: (newImageName: string) => void;
    handleCloseCarouselModal: () => void;
    handleCarouselCreate: (fileName: string, title: string, description: string) => void;
}

function CreateCarouselModal({
    openCreateCarouselModal,
    newImageName,
    previewImage,
    percentage,
    isImageUploaded,
    deleteImage,
    handleCloseCarouselModal,
    handleCarouselCreate,
}: CreateCarouselModalInput) {
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    return (
        <Dialog
            open={openCreateCarouselModal}
            onClose={() => {
                deleteImage(newImageName);
                handleCloseCarouselModal();
            }}
        >
            <DialogTitle>Dodaj novu sliku na pocetnu stranu</DialogTitle>
            <DialogContent>
                <Stack>
                    <Box position='relative'>
                        {isImageUploaded ? (
                            <Box
                                bgcolor='transparent'
                                position='absolute'
                                top='50%'
                                left='50%'
                                sx={{ transform: 'translate(-50%, -50%)' }}
                                overflow='hidden'
                                zIndex={10}
                                width='100%'
                            >
                                <img
                                    style={{ aspectRatio: 1920 / 682, objectFit: 'cover' }}
                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/${newImageName}`}
                                    loading='lazy'
                                    width='100%'
                                />
                            </Box>
                        ) : (
                            <Box
                                bgcolor='white'
                                boxShadow={4}
                                borderRadius={1}
                                px={2}
                                py={1}
                                position='absolute'
                                top='50%'
                                left='50%'
                                sx={{ transform: 'translate(-50%, -50%)' }}
                                overflow='hidden'
                                zIndex={10}
                            >
                                <UploadedPercentageComponent percentage={percentage} />
                            </Box>
                        )}
                        <img style={{ opacity: 0.5 }} width='100%' src={previewImage} />
                    </Box>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='title'
                        label='Naslov'
                        name='title'
                        autoFocus
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='description'
                        label='Opis'
                        type='description'
                        id='description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <DialogActions>
                        <Button
                            variant='text'
                            onClick={() => {
                                setTitle('');
                                setDescription('');
                                deleteImage(newImageName);
                                handleCloseCarouselModal();
                            }}
                        >
                            Odustani
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setTitle('');
                                setDescription('');
                                handleCarouselCreate(newImageName, title, description);
                                handleCloseCarouselModal();
                            }}
                        >
                            Sacuvaj
                        </Button>
                    </DialogActions>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}

export default CreateCarouselModal;
