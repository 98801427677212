export const OrderTypes = {
    ONLINE: 'online',
    LOCAL: 'local',
};

export const OrderPayingMethod = {
    STANDARD: 'standard',
    LEGAL: 'legal',
    TEST: 'test',
};

export const OrderShippingMethod = {
    STANDARD: 'standard',
    SHIPPING: 'shipping',
    TEST: 'test',
};

export const OrderCustomerType = {
    REGULAR: 'regular',
    NEW: 'new',
};
export type OrderCustomerTypeValue = (typeof OrderCustomerType)[keyof typeof OrderCustomerType];

export const OrderStatuses = {
    INITIALIZED: 'initialized',
    PROCESSING: 'processing',
    READY: 'ready',
    SHIPPED: 'shipped',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    DISPUTED: 'disputed',
    REFUNDED: 'refunded',
    TEST: 'test',
    DELETED: 'deleted',
};
export type OrderStatusesValue = (typeof OrderStatuses)[keyof typeof OrderStatuses];

export const OrderActionsType = {
    CREATE: 'create',
    EDIT: 'edit',
};
export type OrderActionsTypeValue = (typeof OrderActionsType)[keyof typeof OrderActionsType];

export const defaultOrderStatusesList = [
    OrderStatuses.INITIALIZED,
    OrderStatuses.PROCESSING,
    OrderStatuses.READY,
    OrderStatuses.SHIPPED,
];
