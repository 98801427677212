import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutAuth, selectAuth } from '../admin/auth/authSlice';
import axios from '../api/axios';
import { setLoading } from '../app/appSlice';
import { store } from '../app/store';

const useLogout = () => {
    const navigate = useNavigate();
    const auth = useSelector(selectAuth);
    const dispatch = store.dispatch;

    const logout = async () => {
        dispatch(setLoading(true));
        try {
            await axios.post(
                '/v2/auths/logout',
                { platformType: 'website' },
                {
                    withCredentials: true,
                    headers: {
                        'x-token-counter': auth.refreshTokenCounter,
                    },
                },
            );
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        } finally {
            dispatch(logoutAuth());
            dispatch(setLoading(false));
            navigate('/', { replace: true });
        }
    };
    return logout;
};

export default useLogout;
