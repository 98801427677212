import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface AdminHeaderInput {
    title: string;
}

function AdminHeader({ title, children }: PropsWithChildren<AdminHeaderInput>) {
    const navigate = useNavigate();
    const navigateBackHandler = () => {
        navigate(-1);
    };
    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent='space-between'
            sx={{
                alignItems: { xs: 'normal', sm: 'center' },
            }}
            spacing={2}
            marginBottom={2}
        >
            <Stack direction='row' spacing={{ xs: 0, md: 2 }} alignItems='center'>
                <Box>
                    <IconButton onClick={navigateBackHandler} size='large'>
                        <ArrowBackIosNew />
                    </IconButton>
                </Box>
                <Typography variant='h3' gutterBottom>
                    {title}
                </Typography>
            </Stack>
            <Stack>{children}</Stack>
        </Stack>
    );
}

export default AdminHeader;
