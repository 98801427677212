import { createSlice } from '@reduxjs/toolkit';
import { Entity } from '../../app/entities/entity';
import { RootState } from '../../app/store';

export interface ApiAuth extends Entity {
    name?: string;
    email: string;
    role?: string;
    verified?: boolean;
    status?: string;
    pushToken?: string;
    pushTokenEnabled?: boolean;
}

interface ApiUser {
    discounts?: object;
    userType?: string;
}

export interface AuthState {
    accessToken?: string;
    auth?: ApiAuth;
    user?: ApiUser;
    accessTokenCounter: number;
    refreshTokenCounter: number;
    isLoggedIn: boolean;
}

const initialState: AuthState = {
    accessToken: undefined,
    auth: {
        _id: '',
        name: undefined,
        email: '',
        role: undefined,
    },
    accessTokenCounter: 0,
    refreshTokenCounter: 0,
    isLoggedIn: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginAuth(state, action) {
            state.accessToken = action.payload.accessToken;
            state.auth = action.payload.auth;
            state.user = action.payload.user;
            state.accessTokenCounter = 1;
            state.refreshTokenCounter = 1;
            state.isLoggedIn = true;
        },
        incrementAccessTokenCounter(state) {
            state.accessTokenCounter += 1;
        },
        incrementRefreshTokenCounter(state) {
            state.refreshTokenCounter += 1;
        },
        setNewAccessToken(state, action) {
            state.accessToken = action.payload;
            state.accessTokenCounter = 1;
            state.refreshTokenCounter += 1;
        },
        logoutAuth: () => initialState,
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const {
    loginAuth,
    logoutAuth,
    incrementAccessTokenCounter,
    incrementRefreshTokenCounter,
    setNewAccessToken,
} = authSlice.actions;

export default authSlice.reducer;
