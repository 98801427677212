import { Add, Print, ReceiptOutlined } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface OrderCreatedModalInput {
    openSuccessCreatedOrderModal: boolean;
    handlePrintDocument: () => void;
    handleCloseSuccessCreatedOrderModal: () => void;
}

function OrderCreatedModal({
    openSuccessCreatedOrderModal,
    handlePrintDocument,
    handleCloseSuccessCreatedOrderModal,
}: OrderCreatedModalInput) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const handleNavigateToOrders = () => {
        navigate('/orders');
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openSuccessCreatedOrderModal}
            onClose={handleCloseSuccessCreatedOrderModal}
        >
            <DialogTitle>Otpremnica uspesno kreirana</DialogTitle>
            <DialogContent>
                <Typography>Otpremnica je uspesno kreirana, izaberi dalju akciju</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    startIcon={<Add />}
                    onClick={handleCloseSuccessCreatedOrderModal}
                >
                    Kreiraj novu
                </Button>
                <Button variant='contained' onClick={handlePrintDocument} startIcon={<Print />}>
                    Odstampaj dokument
                </Button>
                <Button
                    variant='contained'
                    onClick={handleNavigateToOrders}
                    startIcon={<ReceiptOutlined />}
                >
                    Idi na otpremnice
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OrderCreatedModal;
