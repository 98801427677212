import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    status: {
        danger: red[500],
    },
    palette: {
        primary: {
            main: '#fb8b05',
        },
        secondary: {
            main: '#000000de',
        },
    },
    typography: { fontFamily: 'Roboto' },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    '&:hover': {
                        backgroundColor: 'rgba(251, 139, 5, 0.1);',
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                rectangular: {
                    width: '100%',
                },
            },
        },
    },
});

export default theme;
