import { Autocomplete, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { APIOrderProduct, OrderForm } from '../createOrderForm';

interface ProductsFormControllerInput {
    control: Control<OrderForm>;
    products: Array<APIOrderProduct>;
    errors: FieldErrors<OrderForm>;
}

export const ProductsFormController = ({
    control,
    products,
    errors,
}: ProductsFormControllerInput) => {
    if (!products || products.length === 0) {
        return (
            <Stack direction='row' alignItems='center' spacing={2}>
                <Typography>Ucitavanje proizvoda</Typography>
                <CircularProgress color='inherit' />
            </Stack>
        );
    }
    const filterOptions = (
        options: Array<APIOrderProduct>,
        { inputValue }: { inputValue: string },
    ) => {
        const inputKeywords = inputValue.toLowerCase().split(' ');
        return options.filter((option) => {
            const optionKeywords = option.title.toLowerCase().split(' ');
            if (option.uniqueId) {
                optionKeywords.push(option.uniqueId.toLowerCase());
            }
            return inputKeywords.every((keyword) =>
                optionKeywords.some((optionKeyword) => optionKeyword.includes(keyword)),
            );
        });
    };
    return (
        <Controller
            name='product'
            control={control}
            defaultValue={products[0]}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    fullWidth
                    filterOptions={filterOptions}
                    disableClearable
                    options={products}
                    getOptionLabel={(option) =>
                        `[${option.uniqueId}] - (${option.quantity}) - ${option.title}`
                    }
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option._id}>
                                [{option.uniqueId}] - ({option.quantity}) - {option.title}
                            </li>
                        );
                    }}
                    onChange={(_, selectedOption) => {
                        field.onChange(selectedOption);
                    }}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin='normal'
                            label='Proizvod'
                            error={!!errors.product}
                            helperText={errors.product?.message}
                        />
                    )}
                />
            )}
        />
    );
};
