import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginAuth from './admin/auth/view/LoginAuth';
import RegisterAuth from './admin/auth/view/RegisterAuth';
import AuthLandingPage from './admin/auths/AuthLandingPage';
import AuthCreate from './admin/auths/create/AuthCreate';
import AuthEdit from './admin/auths/edit/AuthEdit';
import AdminProtector from './admin/components/AdminProtector';
import LoginProtector from './admin/components/LoginProtector';
import DashboardLandingPage from './admin/dashboard/DashboardLandingPage';
import MainCarouselLandingPage from './admin/mainCarousel/MainCarouselLandingPage';
import OrdersLandingPage from './admin/orders/OrdersLandingPage';
import OrdersCreate from './admin/orders/create/OrdersCreate';
import OrdersEdit from './admin/orders/edit/OrdersEdit';
import Layout from './app/components/Layout';
import Missing from './app/components/Missing';

function App() {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route element={<LoginProtector />}>
                    <Route path='login' element={<LoginAuth />} />
                    <Route path='register' element={<RegisterAuth />} />
                </Route>
                {/* Admin routes */}
                <Route element={<AdminProtector />}>
                    <Route path='/' element={<DashboardLandingPage />} />
                    <Route path='users/*' element={<UsersRouter />} />
                    {/* <Route path='products' element={<ProductsLandingPage />} /> */}
                    <Route path='orders/*' element={<OrdersRouter />} />
                    <Route path='main-carousel' element={<MainCarouselLandingPage />} />
                </Route>
                <Route path='*' element={<Missing />} />
            </Route>
        </Routes>
    );
}

function OrdersRouter() {
    return (
        <Routes>
            <Route path='/' element={<OrdersLandingPage />} />
            <Route path='create' element={<OrdersCreate />} />
            <Route path='edit/:id' element={<OrdersEdit />} />
        </Routes>
    );
}

function UsersRouter() {
    return (
        <Routes>
            <Route path='/' element={<AuthLandingPage />} />
            <Route path='create' element={<AuthCreate />} />
            <Route path='edit/:id' element={<AuthEdit />} />
        </Routes>
    );
}

export default App;
