import axios from 'axios';
const BASE_URL = `${process.env.REACT_APP_SERVER_URL}/api` ?? 'http://localhost:3000/api';

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivateConfig = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

export const axiosMultipartFormData = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'multipart/form-data' },
});
