import { Email, Password, Person } from '@mui/icons-material';
import {
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListSubheader,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { UpdateAuthForm } from '../forms/updateAuthForm';
import {
    AuthRolesEnum,
    AuthStatusesEnum,
    authRolesArray,
    authStatusesArray,
    mapRSAuthRoles,
    mapRSAuthStatuses,
} from '../types/user';

interface AuthDetailsEditFormInput {
    control: Control<UpdateAuthForm>;
    errors: FieldErrors<UpdateAuthForm>;
    email: string;
}

const AuthDetailsEditForm = ({ control, errors, email }: AuthDetailsEditFormInput) => {
    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component={Card}
            subheader={<ListSubheader>Osnovni podaci</ListSubheader>}
        >
            <Divider />
            <ListItem>
                <ListItemIcon>
                    <Person fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='name'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Ime'
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    )}
                />
            </ListItem>
            <ListItem sx={{ my: 1 }}>
                <ListItemIcon>
                    <Email fontSize='medium' />
                </ListItemIcon>
                <Typography>{email}</Typography>
            </ListItem>
            <ListItem sx={{ my: 1 }}>
                <ListItemIcon>
                    <Password fontSize='medium' />
                </ListItemIcon>
                <Typography>***********</Typography>
            </ListItem>
            {/* TODO izmeni lozinku */}
            <ListItem>
                <Controller
                    name='role'
                    control={control}
                    defaultValue={AuthRolesEnum.user}
                    render={({ field }) => (
                        <FormControl {...field} fullWidth>
                            <InputLabel id='auth-role'>Uloga korisnika</InputLabel>
                            <Select {...field} label='Uloga korisnika' labelId='auth-role'>
                                {authRolesArray.map((authRole) => {
                                    if (
                                        authRole === AuthRolesEnum.test ||
                                        authRole === AuthRolesEnum.superadmin
                                    ) {
                                        return;
                                    }
                                    return (
                                        <MenuItem key={authRole} value={authRole}>
                                            {mapRSAuthRoles(authRole)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                />
            </ListItem>
            <ListItem>
                <Controller
                    name='status'
                    control={control}
                    defaultValue={AuthStatusesEnum.active}
                    render={({ field }) => (
                        <FormControl {...field} fullWidth>
                            <InputLabel id='auth-status'>Status korisnika</InputLabel>
                            <Select {...field} label='Status korisnika' labelId='auth-status'>
                                {authStatusesArray.map((authStatus) => {
                                    return (
                                        <MenuItem key={authStatus} value={authStatus}>
                                            {mapRSAuthStatuses(authStatus)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                />
            </ListItem>
            <Divider>Verifikovan</Divider>
            <ListItem>
                <Controller
                    name='verified'
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value={true} control={<Radio />} label='Da' />
                            <FormControlLabel value={false} control={<Radio />} label='Ne' />
                        </RadioGroup>
                    )}
                />
            </ListItem>
        </List>
    );
};

export default AuthDetailsEditForm;
