import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectAuth } from '../auth/authSlice';
import AppDrawer from '../navigation/AppDrawer';
import NavigationBar from '../navigation/NavigationBar';

const AdminProtector = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 600);
    const [open, setOpen] = useState(isDesktop);

    const auth = useSelector(selectAuth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 600);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return auth.isLoggedIn ? (
        <Box sx={{ display: 'flex' }}>
            <NavigationBar open={open} handleDrawerOpen={handleDrawerOpen} />
            <AppDrawer open={open} handleDrawerClose={handleDrawerClose} />
            <Container
                maxWidth={false}
                sx={{ mt: 10, width: window.innerWidth - (open ? 240 : 57) }}
            >
                <Outlet />
            </Container>
        </Box>
    ) : (
        <Navigate to='/login' state={{ from: location }} replace />
    );
};

export default AdminProtector;
