import { Add } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { RefObject, createRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLoading } from '../../app/appSlice';
import ButtonLink from '../../app/components/ButtonLink';
import { store } from '../../app/store';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminHeader from '../components/AdminHeader';
import SearchComponent from '../components/SearchComponent';
import AuthTable from './AuthTable';
import { deleteAuthRequest } from './repositories/APIAuthsRepository';
import { Auth } from './types/user';

function AuthLandingPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const page = Number(queryParams.get('page')) ?? 0;
    const search = queryParams.get('search') ?? '';

    const tableRef: RefObject<HTMLTableElement> = createRef<HTMLTableElement>();
    const [auths, setAuths] = React.useState<Auth[]>([]);
    const [authsCount, setAuthsCount] = React.useState(0);
    const axiosPrivate = useAxiosPrivate();
    const dispatch = store.dispatch;

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadUsers() {
            dispatch(setLoading(true));
            try {
                const response = await axiosPrivate.get('/v2/auths', {
                    params: queryParams,
                    signal: abortController.signal,
                });

                if (response.data.auths && isMounted) {
                    setAuths(response.data.auths);
                    setAuthsCount(response.data.authsCount);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                dispatch(setLoading(false));
                isMounted = false;
            }
        }
        loadUsers();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [page, search]);

    const handleChangePage = (event: unknown, newPage: number) => {
        queryParams.set('page', newPage.toString());
        tableRef.current && tableRef.current.scrollIntoView();
        navigate({ search: queryParams.toString() });
    };

    const handleDeleteAuth = async (id: string) => {
        try {
            const createdAuth = await deleteAuthRequest(id, axiosPrivate);
            if (!createdAuth) {
                throw Error('Korisnik nije obrisan');
            }
            setAuths((prevAuths) => prevAuths.filter((auth) => auth._id !== id));
            // handleOpenSuccessCreatedOrderModal();
        } catch (validationError) {
            console.error(validationError);
        }
    };

    return (
        <Box justifyContent='flex-start'>
            <AdminHeader title='Korisnici'>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <SearchComponent queryParams={queryParams} searchValue={search} />
                    <ButtonLink href='./create'>
                        <Add />
                    </ButtonLink>
                </Stack>
            </AdminHeader>
            <AuthTable
                auths={auths}
                authsCount={authsCount}
                page={page}
                tableRef={tableRef}
                handleChangePage={handleChangePage}
                onDeleteAuth={handleDeleteAuth}
            />
        </Box>
    );
}

export default AuthLandingPage;
