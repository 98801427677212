import { Brand } from './brand';
import { Category } from './category';
import { Entity } from './entity';
import { SubCategory } from './subCategory';

export interface APIProduct extends Entity {
    uniqueId?: string;
    title: string;
    slug?: string;
    description?: string;
    keywords?: string;
    price: number;
    optionalPrice?: string;
    discountPrice?: number;
    priceUnit?: string;
    purchasePrice?: number;
    purchaseMargin?: number;
    category?: Category | string;
    subCategory?: SubCategory | string;
    brand?: Brand | string;
    model?: string;
    quantity: number;
    minQuantityWebsite?: number;
    minReplenishment?: number;
    diameter?: number;
    offset?: number;
    cb?: number;
    span?: string;
    height?: number;
    width?: number;
    season?: string;
    barcode?: string;
    status?: string;
    images?: Array<string>;
    reserved?: number;
    adminReservation?: number;
    location?: string;
    created?: Date;
    createdBy?: string;
    updated?: Date;
    updatedBy?: string;
    sort?: number;
    connectedProduct?: string;
}

export const getCategoryTitle = (category?: Category | string): string => {
    if (!category) {
        return '';
    }
    if (isInstanceOfCategory(category)) {
        return category.title;
    }
    return category;
};

export const getCategory = (category?: Category | string): Category | undefined => {
    if (!category) {
        return undefined;
    }
    if (isInstanceOfCategory(category)) {
        return category;
    }
    return undefined;
};

const isInstanceOfCategory = (object: any): object is Category => {
    return typeof object === 'object' && 'title' in object && 'slug' in object;
};

export const getSubCategoryTitle = (subCategory?: SubCategory | string): string => {
    if (!subCategory) {
        return '/';
    }
    if (isInstanceOfSubCategory(subCategory)) {
        return subCategory.title;
    }
    return subCategory;
};

export const getSubCategory = (subCategory?: SubCategory | string): SubCategory | undefined => {
    if (!subCategory) {
        return undefined;
    }
    if (isInstanceOfSubCategory(subCategory)) {
        return subCategory;
    }
    return undefined;
};

const isInstanceOfSubCategory = (object: any): object is SubCategory => {
    return typeof object === 'object' && 'title' in object && 'slug' in object;
};
