import { isAxiosError } from 'axios';
import { useSelector } from 'react-redux';
import {
    incrementRefreshTokenCounter,
    selectAuth,
    setNewAccessToken,
} from '../admin/auth/authSlice';
import axios from '../api/axios';
import { store } from '../app/store';
import useLogout from './useLogout';

const useRefreshToken = () => {
    const logout = useLogout();
    const auth = useSelector(selectAuth);
    const dispatch = store.dispatch;
    const refresh = async () => {
        try {
            const response = await axios.post(
                '/v2/auths/refresh-token',
                { platformType: 'website' },
                {
                    withCredentials: true,
                    headers: {
                        'x-token-counter': auth.refreshTokenCounter,
                    },
                },
            );
            const { accessToken } = response.data;
            dispatch(setNewAccessToken(accessToken));
            return accessToken;
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response?.status === 401) {
                    alert(error.response?.data.message);
                    await logout();
                    return null;
                }
                dispatch(incrementRefreshTokenCounter());
                alert(error.response?.data.message);
            } else {
                alert(`Error: ${JSON.stringify(error)}`);
            }
            return null;
        }
    };
    return refresh;
};

export default useRefreshToken;
