export const tableHeight = window.innerHeight - 204;
export const imagesSizes = {
    THUMBNAIL: 'tb_',
    SMALL: 'tn_',
    REGULAR: '',
    ORIGINAL: 'hd_',
};

export const countries: string[] = [
    'Srbija',
    'Avganistan',
    'Albanija',
    'Alžir',
    'Andora',
    'Angola',
    'Antigva i Barbuda',
    'Argentina',
    'Jermenija',
    'Australija',
    'Austrija',
    'Azerbejdžan',
    'Bahami',
    'Bahrein',
    'Bangladeš',
    'Barbados',
    'Belorusija',
    'Belgija',
    'Belize',
    'Benin',
    'Butan',
    'Bolivija',
    'Bosna i Hercegovina',
    'Bocvana',
    'Brazil',
    'Brunej',
    'Bugarska',
    'Burkina Faso',
    'Burundi',
    'Kabo Verde',
    'Kambodža',
    'Kamerun',
    'Kanada',
    'Centralnoafrička Republika',
    'Čad',
    'Čile',
    'Kina',
    'Kolumbija',
    'Komori',
    'Kongo',
    'Kostarika',
    'Hrvatska',
    'Kuba',
    'Kipar',
    'Češka',
    'Demokratska Republika Kongo',
    'Danska',
    'Džibuti',
    'Dominika',
    'Dominikanska Republika',
    'Istočni Timor',
    'Ekvador',
    'Egipat',
    'Salvador',
    'Ekvatorska Gvineja',
    'Eritreja',
    'Estonija',
    'Esvatini',
    'Etiopija',
    'Fidži',
    'Finska',
    'Francuska',
    'Gabon',
    'Gambija',
    'Gruzija',
    'Nemačka',
    'Gana',
    'Grčka',
    'Grenada',
    'Gvatemala',
    'Gvineja',
    'Gvineja-Bisau',
    'Gvajana',
    'Haiti',
    'Honduras',
    'Mađarska',
    'Island',
    'Indija',
    'Indonezija',
    'Iran',
    'Irak',
    'Irska',
    'Izrael',
    'Italija',
    'Obala Slonovače',
    'Jamajka',
    'Japan',
    'Jordan',
    'Kazahstan',
    'Kenija',
    'Kiribati',
    'Kuvajt',
    'Kirgistan',
    'Laos',
    'Letonija',
    'Liban',
    'Lesoto',
    'Liberija',
    'Libija',
    'Lihtenštajn',
    'Litvanija',
    'Luksemburg',
    'Madagaskar',
    'Malavi',
    'Malezija',
    'Maldivi',
    'Mali',
    'Malta',
    'Maršalova Ostrva',
    'Mauritanija',
    'Mauricijus',
    'Meksiko',
    'Mikronezija',
    'Moldavija',
    'Monako',
    'Mongolija',
    'Crna Gora',
    'Maroko',
    'Mozambik',
    'Mjanmar',
    'Namibija',
    'Nauru',
    'Nepal',
    'Holandija',
    'Novi Zeland',
    'Nikaragva',
    'Niger',
    'Nigerija',
    'Severna Koreja',
    'Severna Makedonija',
    'Norveška',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestina',
    'Panama',
    'Papua Nova Gvineja',
    'Paragvaj',
    'Peru',
    'Filipini',
    'Poljska',
    'Portugalija',
    'Katar',
    'Rumunija',
    'Rusija',
    'Ruanda',
    'Sveti Kits i Nevis',
    'Sveta Lucija',
    'Sveti Vinsent i Grenadini',
    'Samoa',
    'San Marino',
    'Sao Tome i Principe',
    'Saudijska Arabija',
    'Senegal',
    'Sejšeli',
    'Sijera Leone',
    'Singapur',
    'Slovačka',
    'Slovenija',
    'Solomonska Ostrva',
    'Somalija',
    'Južnoafrička Republika',
    'Južna Koreja',
    'Južni Sudan',
    'Španija',
    'Šri Lanka',
    'Sudan',
    'Surinam',
    'Švedska',
    'Švajcarska',
    'Sirija',
    'Tajvan',
    'Tadžikistan',
    'Tanzanija',
    'Tajland',
    'Togo',
    'Tonga',
    'Trinidad i Tobago',
    'Tunis',
    'Turska',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukrajina',
    'Ujedinjeni Arapski Emirati',
    'Ujedinjeno Kraljevstvo',
    'Sjedinjene Američke Države',
    'Urugvaj',
    'Uzbekistan',
    'Vanuatu',
    'Vatikan',
    'Venecuela',
    'Vijetnam',
    'Jemen',
    'Zambija',
    'Zimbabve',
];
