import { Close, Delete } from '@mui/icons-material';
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import ImageModal, { getImageId, getImageURL } from '../../../app/components/ImageModal';
import { calculateTotalPrice } from '../repositories/APIOrdersRepository';
import { OrderLine } from './createOrderForm';

interface OrderLinesTableInput {
    orderLines: Array<OrderLine>;
    onRemoveFromOrders: (id: string) => void;
    onResetPrice: (id: string) => void;
    onChangePrice: (id: string, price: number) => void;
    onChangeDiscount: (id: string, discount: number) => void;
    onChangeDesiredQuantity: (id: string, quantity: number) => void;
    disableOrderLineInput?: boolean;
}
function OrderLinesTable({
    orderLines,
    onRemoveFromOrders,
    onResetPrice,
    onChangePrice,
    onChangeDiscount,
    onChangeDesiredQuantity,
    disableOrderLineInput,
}: OrderLinesTableInput) {
    const [imageId, setImageId] = React.useState('');
    const [openImageModal, setOpenImageModal] = React.useState(false);

    const handleOpenImageModal = () => setOpenImageModal(true);
    const handleCloseImageModal = () => setOpenImageModal(false);

    const handleChangeOption = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        id: string,
        option: string,
    ) => {
        const validatedInput = validateInputNumber(e.target.value);
        if (validatedInput === undefined) return;
        switch (option) {
            case 'price':
                onChangePrice(id, validatedInput);
                break;
            case 'discount':
                onChangeDiscount(id, validatedInput);
                break;
            case 'quantity':
                onChangeDesiredQuantity(id, validatedInput);
                break;

            default:
                break;
        }
    };

    return (
        <Box justifyContent='flex-start'>
            <ImageModal
                imageId={imageId}
                openImageModal={openImageModal}
                handleCloseImageModal={handleCloseImageModal}
            />
            <Stack>
                <Typography variant='h4' gutterBottom>
                    Proizvodi
                </Typography>
                <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Slika</TableCell>
                                <TableCell>Sifra</TableCell>
                                <TableCell>Naziv</TableCell>
                                <TableCell>Na stanju</TableCell>
                                <TableCell>Originalna cena</TableCell>
                                <TableCell>Cena</TableCell>
                                <TableCell>Popust</TableCell>
                                <TableCell>Kolicina</TableCell>
                                <TableCell>Ukupno</TableCell>
                                <TableCell>Akcije</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderLines && orderLines.length > 0
                                ? orderLines.map((order) => {
                                      const imageId = getImageId(order.images);
                                      const totalPrice = calculateTotalPrice(order);
                                      return (
                                          <TableRow
                                              hover
                                              key={order.productId}
                                              sx={{
                                                  '&:last-child td, &:last-child th': {
                                                      border: 0,
                                                  },
                                              }}
                                          >
                                              <TableCell component='th' scope='row'>
                                                  <img
                                                      onClick={() => {
                                                          setImageId(imageId);
                                                          handleOpenImageModal();
                                                      }}
                                                      src={getImageURL(imageId)}
                                                      alt={order.title}
                                                      style={{ cursor: 'zoom-in' }}
                                                      width={50}
                                                  />
                                              </TableCell>
                                              <TableCell>{order.uniqueId}</TableCell>
                                              <TableCell>{order.title}</TableCell>
                                              <TableCell>{order.quantity} kom</TableCell>
                                              <TableCell>
                                                  {order.originalPrice} {order.priceUnit}
                                              </TableCell>
                                              <TableCell>
                                                  <FormControl
                                                      sx={{ width: '25ch' }}
                                                      margin='normal'
                                                      variant='outlined'
                                                      disabled={disableOrderLineInput}
                                                  >
                                                      <OutlinedInput
                                                          id='price'
                                                          startAdornment={
                                                              <InputAdornment position='start'>
                                                                  {order.priceUnit}
                                                              </InputAdornment>
                                                          }
                                                          endAdornment={
                                                              <InputAdornment position='end'>
                                                                  <IconButton
                                                                      onClick={() =>
                                                                          onResetPrice(
                                                                              order.productId,
                                                                          )
                                                                      }
                                                                      disabled={
                                                                          disableOrderLineInput
                                                                      }
                                                                  >
                                                                      <Close />
                                                                  </IconButton>
                                                              </InputAdornment>
                                                          }
                                                          value={Math.round(order.price)}
                                                          onChange={(e) =>
                                                              handleChangeOption(
                                                                  e,
                                                                  order.productId,
                                                                  'price',
                                                              )
                                                          }
                                                      />
                                                  </FormControl>
                                              </TableCell>
                                              <TableCell>
                                                  <FormControl
                                                      sx={{ width: '10ch' }}
                                                      margin='normal'
                                                      variant='outlined'
                                                      disabled={disableOrderLineInput}
                                                  >
                                                      <OutlinedInput
                                                          id='discount'
                                                          endAdornment={
                                                              <InputAdornment position='end'>
                                                                  %
                                                              </InputAdornment>
                                                          }
                                                          value={Math.round(
                                                              order.discountPercentage,
                                                          )}
                                                          onChange={(e) =>
                                                              handleChangeOption(
                                                                  e,
                                                                  order.productId,
                                                                  'discount',
                                                              )
                                                          }
                                                      />
                                                  </FormControl>
                                              </TableCell>
                                              <TableCell>
                                                  <FormControl
                                                      sx={{ width: '14ch' }}
                                                      margin='normal'
                                                      variant='outlined'
                                                      disabled={disableOrderLineInput}
                                                  >
                                                      <OutlinedInput
                                                          id='desiredQuantity'
                                                          endAdornment={
                                                              <InputAdornment position='end'>
                                                                  kom
                                                              </InputAdornment>
                                                          }
                                                          value={order.desiredQuantity}
                                                          onChange={(e) =>
                                                              handleChangeOption(
                                                                  e,
                                                                  order.productId,
                                                                  'quantity',
                                                              )
                                                          }
                                                      />
                                                  </FormControl>
                                              </TableCell>
                                              <TableCell>
                                                  {Math.round(totalPrice)} {order.priceUnit}
                                              </TableCell>
                                              <TableCell>
                                                  <IconButton
                                                      onClick={() =>
                                                          onRemoveFromOrders(order.productId)
                                                      }
                                                      disabled={disableOrderLineInput}
                                                  >
                                                      <Delete />
                                                  </IconButton>
                                              </TableCell>
                                          </TableRow>
                                      );
                                  })
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
    );
}

const validateInputNumber = (input: string) => {
    if (!/^\d*$/.test(input)) {
        return;
    }
    return Number(input);
};

export default OrderLinesTable;
