import { Box, Container, Typography } from '@mui/material';

function DashboardLandingPage() {
    return (
        <Container component='main'>
            <Box>
                <Typography>Dashboard</Typography>
            </Box>
        </Container>
    );
}

export default DashboardLandingPage;
