import { Entity } from '../../../app/entities/entity';

export const AuthRolesEnum = {
    superadmin: 'superadmin',
    admin: 'admin',
    manager: 'manager',
    shopkeeper: 'shopkeeper',
    partner: 'partner',
    user: 'user',
    test: 'test',
} as const;
export type AuthRolesType = (typeof AuthRolesEnum)[keyof typeof AuthRolesEnum];
export const authRolesArray = Object.values(AuthRolesEnum);
export const mapRSAuthRoles = (role: AuthRolesType) => {
    switch (role) {
        case 'admin':
            return 'Admin';
        case 'manager':
            return 'Menadzer';
        case 'partner':
            return 'Partner';
        case 'shopkeeper':
            return 'Magacioner';
        case 'superadmin':
            return 'Superadmin';
        case 'user':
            return 'Korisnik';
        case 'test':
            return 'Test';

        default:
            return 'Nepoznata uloga';
    }
};

export const AuthStatusesEnum = {
    active: 'active',
    disabled: 'disabled',
    deleted: 'deleted',
} as const;
export type AuthStatusesType = (typeof AuthStatusesEnum)[keyof typeof AuthStatusesEnum];
export const authStatusesArray = Object.values(AuthStatusesEnum);
export const mapRSAuthStatuses = (role: AuthStatusesType) => {
    switch (role) {
        case 'active':
            return 'Aktivan';
        case 'disabled':
            return 'Blokiran';
        case 'deleted':
            return 'Obrisan';

        default:
            return 'Nepoznat status';
    }
};

const TokenTypeEnum = {
    access: 'access',
    refresh: 'refresh',
} as const;
type TokenTypesType = (typeof TokenTypeEnum)[keyof typeof TokenTypeEnum];

const PlatformTypeEnum = {
    website: 'website',
    mobile: 'mobile',
} as const;
type PlatformTypesType = (typeof PlatformTypeEnum)[keyof typeof PlatformTypeEnum];

export interface Token {
    token: string;
    tokenType: TokenTypesType;
    platformType: PlatformTypesType;
    counter: number;
}

export interface Auth extends Entity {
    name: string;
    email: string;
    password: string;
    created: Date;
    updated: Date;
    role: AuthRolesType;
    status: AuthStatusesType;
    verified: boolean;
    hash: string;
    googleId: string;
    pushToken: string;
    pushTokenEnabled: boolean;
    tokens: Array<Token>;
}

const UserTypeEnum = {
    natural: 'natural',
    legal: 'legal',
} as const;
export type UserTypesType = (typeof UserTypeEnum)[keyof typeof UserTypeEnum];

export interface User extends Entity {
    name: string;
    lastName: string;
    dateOfBirth: Date;
    image: string;
    country: string;
    town: string;
    address: string;
    zip: string;
    phone: string;
    userType: UserTypesType;
    companyName: string;
    companyNumber: string;
    companyTaxNumber: string;
    companyActivityCode: string;
    companyCountry: string;
    companyTown: string;
    companyAddress: string;
    companyZip: string;
    companyPhone: string;
    cart: object;
    discounts: object;
    auth: string;
    updated: Date;
    updatedBy: string;
}

export const PASSWORD_REGEX = /(?=.*\d)(?=.*[a-z]).{8,}/;
