import { isCancel } from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { incrementAccessTokenCounter, selectAuth } from '../admin/auth/authSlice';
import { axiosPrivateConfig } from '../api/axios';
import { setLoading } from '../app/appSlice';
import { store } from '../app/store';
import useRefreshToken from './useRefreshToken';

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const auth = useSelector(selectAuth);
    const dispatch = store.dispatch;

    useEffect(() => {
        const requestIntercept = axiosPrivateConfig.interceptors.request.use(
            (config) => {
                dispatch(setLoading(true));
                if (!config.headers['Authorization'] && auth.isLoggedIn) {
                    config.headers['Authorization'] = `Bearer ${auth.accessToken}`;
                    config.headers['x-token-counter'] = auth.accessTokenCounter;
                }
                return config;
            },
            (error) => Promise.reject(error),
        );

        const responseIntercept = axiosPrivateConfig.interceptors.response.use(
            (response) => {
                if (auth.isLoggedIn) {
                    dispatch(incrementAccessTokenCounter());
                }
                dispatch(setLoading(false));
                return response;
            },
            async (error) => {
                if (isCancel(error)) {
                    dispatch(setLoading(false));
                    return console.error('Request canceled', error.message);
                }
                if (!auth.isLoggedIn) {
                    dispatch(setLoading(false));
                    return Promise.reject(error);
                } else {
                    dispatch(incrementAccessTokenCounter());
                }
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    if (!newAccessToken) {
                        dispatch(setLoading(false));
                        return Promise.reject(error);
                    }
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    prevRequest.headers['x-token-counter'] = 1;
                    dispatch(setLoading(false));
                    return axiosPrivateConfig(prevRequest);
                }
                dispatch(setLoading(false));
                return Promise.reject(error);
            },
        );

        return () => {
            axiosPrivateConfig.interceptors.request.eject(requestIntercept);
            axiosPrivateConfig.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return axiosPrivateConfig;
};

export default useAxiosPrivate;
