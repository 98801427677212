import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React from 'react';
import { mapRSStatus } from './OrdersTable';
import { OrderStatuses, defaultOrderStatusesList } from './enums';

interface FiltersModalInput {
    openFiltersModal: boolean;
    handleCloseFilterModal: () => void;
    onFilterApplied: (values: Array<string>, isPaidTrue: boolean, isPaidFalse: boolean) => void;
}

const FiltersModal = ({
    openFiltersModal,
    handleCloseFilterModal,
    onFilterApplied,
}: FiltersModalInput) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [orderStatusesList, setOrderStatusesList] =
        React.useState<Array<string>>(defaultOrderStatusesList);
    const [isPaidTrue, setIsPaidTrue] = React.useState<boolean>(true);
    const [isPaidFalse, setIsPaidFalse] = React.useState<boolean>(true);

    const handleChangeStatus = (event: SelectChangeEvent<typeof orderStatusesList>) => {
        const {
            target: { value },
        } = event;
        setOrderStatusesList(typeof value === 'string' ? value.split(',') : value);
    };

    const handleChangeIsPaidTrue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPaidTrue(event.target.checked);
    };
    const handleChangeIsPaidFalse = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPaidFalse(event.target.checked);
    };
    return (
        <Dialog
            fullScreen={fullScreen}
            open={openFiltersModal}
            maxWidth='sm'
            fullWidth
            onClose={handleCloseFilterModal}
        >
            <DialogTitle>Filteri</DialogTitle>
            <DialogContent>
                <FormControl variant='standard' fullWidth>
                    <InputLabel id='orders-status-filter'>Status</InputLabel>
                    <Select
                        labelId='orders-status-filter'
                        multiple
                        autoWidth={false}
                        value={orderStatusesList}
                        onChange={handleChangeStatus}
                        renderValue={(selected) =>
                            selected.map((item) => mapRSStatus(item)).join(', ')
                        }
                    >
                        {Object.values(OrderStatuses).map((status) => {
                            if (status === OrderStatuses.TEST || status === OrderStatuses.DELETED) {
                                return;
                            }
                            return (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={orderStatusesList.indexOf(status) > -1} />
                                    <ListItemText primary={mapRSStatus(status)} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={isPaidTrue} onChange={handleChangeIsPaidTrue} />
                        }
                        label='Placeno'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={isPaidFalse} onChange={handleChangeIsPaidFalse} />
                        }
                        label='Nije placeno'
                    />
                </FormGroup>
                <DialogActions>
                    <Button variant='text' onClick={handleCloseFilterModal}>
                        Odustani
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => {
                            handleCloseFilterModal();
                            onFilterApplied(orderStatusesList, isPaidTrue, isPaidFalse);
                        }}
                    >
                        Sacuvaj
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default FiltersModal;
