import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonLinkProps {
    href?: string | undefined;
}

const ButtonLink: React.FC<React.PropsWithChildren<ButtonLinkProps>> = ({ href, children }) => {
    return (
        <Button component={Link} to={href ?? '#'} variant='contained'>
            {children}
        </Button>
    );
};

export default ButtonLink;
