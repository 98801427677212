import { Add, Edit, ReceiptOutlined } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface AuthCreatedModalInput {
    id?: string;
    openSuccessCreatedOrderModal: boolean;
    handleEditCurrentUser: (id?: string) => void;
    handleCloseSuccessCreatedOrderModal: () => void;
}

function AuthCreatedModal({
    id,
    openSuccessCreatedOrderModal,
    handleEditCurrentUser,
    handleCloseSuccessCreatedOrderModal,
}: AuthCreatedModalInput) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const handleNavigateToAuths = () => {
        navigate('/users');
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openSuccessCreatedOrderModal}
            onClose={handleCloseSuccessCreatedOrderModal}
        >
            <DialogTitle>Korisnik uspesno kreiran</DialogTitle>
            <DialogContent>
                <Typography>Korisnik je uspesno kreiran, izaberi dalju akciju</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    startIcon={<Add />}
                    onClick={handleCloseSuccessCreatedOrderModal}
                >
                    Kreiraj novog
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleEditCurrentUser(id)}
                    startIcon={<Edit />}
                >
                    Dodaj detalje
                </Button>
                <Button
                    variant='contained'
                    onClick={handleNavigateToAuths}
                    startIcon={<ReceiptOutlined />}
                >
                    Idi na korisnike
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AuthCreatedModal;
