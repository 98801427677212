import { joiResolver } from '@hookform/resolvers/joi';
import { Add, Save } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { APIOrder } from '../../../app/entities/order';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Auth, User } from '../../auths/types/user';
import AdminHeader from '../../components/AdminHeader';
import LegalCustomerDetailsForm from '../LegalCustomerDetailsForm';
import ShippingDetailsForm from '../ShippingDetailsForm';
import { OrderCustomerType, OrderPayingMethod, OrderShippingMethod, OrderTypes } from '../enums';
import {
    createOrderRequest,
    mapProductToOrderLine,
    requestDocumentPrint,
    validateCreateOrderInput,
} from '../repositories/APIOrdersRepository';
import OrderCreatedModal from './OrderCreatedModal';
import OrderLinesTable from './OrdersCreateTable';
import {
    APIOrderProduct,
    OrderAuth,
    OrderForm,
    OrderLine,
    createOrderLineSchema,
} from './createOrderForm';
import OrdersActions from './formControllers/OrdersActions';
import { ProductsFormController } from './formControllers/ProductsFormController';

function OrdersCreate() {
    const [apiOrder, setApiOrder] = React.useState<APIOrder>();
    const [productsList, setProductsList] = React.useState<Array<APIOrderProduct>>([]);
    const [orderLineList, setOrderLineList] = React.useState<Array<OrderLine>>([]);
    const [orderAuthList, setOrderAuthList] = React.useState<Array<OrderAuth>>([]);
    const [displayShippingDetailsForm, setDisplayShippingDetailsForm] =
        React.useState<boolean>(false);
    const [displayLegalCustomerDetailsForm, setDisplayLegalCustomerDetailsForm] =
        React.useState<boolean>(false);
    const [openSuccessCreatedOrderModal, setOpenSuccessCreatedOrderModal] =
        React.useState<boolean>(false);
    const axiosPrivate = useAxiosPrivate();

    const {
        control,
        formState: { errors, isDirty, isSubmitting },
        getValues,
        handleSubmit,
        clearErrors,
        reset,
        setValue,
    } = useForm<OrderForm>({
        resolver: joiResolver(createOrderLineSchema),
        mode: 'onBlur',
        defaultValues: {
            isPaid: false,
            isAssemblyRequired: false,
            shippingMethod: OrderShippingMethod.STANDARD,
            payingMethod: OrderPayingMethod.STANDARD,
            orderType: OrderTypes.LOCAL,
        },
    });

    const handleOpenSuccessCreatedOrderModal = () => {
        setOpenSuccessCreatedOrderModal(true);
    };

    const handleCloseSuccessCreatedOrderModal = () => {
        setOpenSuccessCreatedOrderModal(false);
        setOrderLineList([]);
        reset();
    };

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.error('Form has errors:', errors);
        }
    }, [errors]);

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadOrderProducts() {
            try {
                const responseProducts = await axiosPrivate.get('/v2/products/orders', {
                    signal: abortController.signal,
                });

                if (responseProducts && responseProducts.data.products && isMounted) {
                    setProductsList(responseProducts.data.products);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        loadOrderProducts();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, []);

    const customerType = useWatch({
        control,
        name: 'customerType',
        defaultValue: OrderCustomerType.NEW,
    });
    // const { data, error } = useOrderAuths(customerType, axiosPrivate);
    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadOrderAuths() {
            try {
                const responseAuths = await axiosPrivate.get('/v2/auths/orders', {
                    signal: abortController.signal,
                });

                if (responseAuths && responseAuths.data.auths && isMounted) {
                    setOrderAuthList(responseAuths.data.auths);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        if (customerType === OrderCustomerType.REGULAR && orderAuthList.length === 0) {
            loadOrderAuths();
        }
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [customerType]);

    const getSingleProduct = async (id: string) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.get(`/v2/products/${id}`, {
                signal: abortController.signal,
            });

            // TODO: revisit logic fro newOrderLine
            if (response && response.data.product) {
                const newOrderLine = mapProductToOrderLine(response.data.product);
                if (!newOrderLine) {
                    return;
                }
                setOrderLineList((prevOrders) => [...prevOrders, newOrderLine]);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const getSingleAuth = async (id: string) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.get(`/v2/auths/${id}`, {
                signal: abortController.signal,
            });

            if (response && response.data.auth && response.data.user) {
                const auth: Auth = response.data.auth;
                const user: User = response.data.user;
                setValue('companyZip', user.companyZip);
                setValue('companyTown', user.companyTown);
                setValue('companyTaxNumber', user.companyTaxNumber);
                setValue('companyPhone', user.companyPhone);
                setValue('companyNumber', user.companyNumber);
                setValue('companyName', user.companyName);
                setValue('companyAddress', user.companyAddress);
                setValue('name', user.name);
                setValue('country', user.country);
                setValue('town', user.town);
                setValue('zip', user.zip);
                setValue('address', user.address);
                setValue('phone', user.phone);
                setValue('email', auth.email);
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const shippingMethod = useWatch({
        control,
        name: 'shippingMethod',
        defaultValue: OrderShippingMethod.STANDARD,
    });
    const payingMethod = useWatch({
        control,
        name: 'payingMethod',
        defaultValue: OrderPayingMethod.STANDARD,
    });
    React.useEffect(() => {
        const isShippingRequired = shippingMethod === OrderShippingMethod.SHIPPING;
        setDisplayShippingDetailsForm(isShippingRequired);
        if (!isShippingRequired) {
            clearErrors(['name', 'country', 'town', 'address', 'zip', 'phone', 'email']);
        }
        const isLegalCustomer = payingMethod === OrderPayingMethod.LEGAL;
        setDisplayLegalCustomerDetailsForm(isLegalCustomer);
        if (!isLegalCustomer) {
            clearErrors([
                'companyAddress',
                'companyName',
                'companyNumber',
                'companyPhone',
                'companyTaxNumber',
                'companyTown',
                'companyZip',
            ]);
        }
    }, [shippingMethod, payingMethod]);

    const addProductToList = async () => {
        const values = getValues();
        const alreadyAdded = orderLineList.find((order) => order.productId === values.product._id);
        if (alreadyAdded) {
            enqueueSnackbar(`Proizvod ${alreadyAdded.title} je vec dodat.`, { variant: 'warning' });
            return;
        }
        await getSingleProduct(values.product._id);
    };

    const handleRemoveFromOrders = (id: string) => {
        setOrderLineList((prevOrders) => prevOrders.filter((order) => order.productId !== id));
    };

    const handleChangeDesiredQuantity = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) =>
            order.productId === id ? { ...order, desiredQuantity: validatedInput } : order,
        );
        setOrderLineList(updatedOrders);
    };

    const handleChangeDiscount = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) => {
            const price = ((100 - validatedInput) / 100) * order.originalPrice;
            const updatedOrder = {
                ...order,
                price: validatedInput > 0 ? price : order.originalPrice,
                discountPercentage: validatedInput,
            };
            return order.productId === id ? updatedOrder : order;
        });
        setOrderLineList(updatedOrders);
    };

    const handleResetPrice = (id: string) => {
        const updatedOrders = orderLineList.map((order) =>
            order.productId === id
                ? {
                      ...order,
                      price: order.originalPrice,
                      discountPercentage: 0,
                  }
                : order,
        );
        setOrderLineList(updatedOrders);
    };

    const handleChangePrice = (id: string, validatedInput: number) => {
        const updatedOrders = orderLineList.map((order) => {
            const discount = ((order.originalPrice - validatedInput) / order.originalPrice) * 100;
            const updatedOrder = {
                ...order,
                price: validatedInput,
                discountPercentage: validatedInput ? discount : 0,
            };
            return order.productId === id ? updatedOrder : order;
        });
        setOrderLineList(updatedOrders);
    };

    const onSubmit = async (data: OrderForm) => {
        try {
            validateCreateOrderInput(orderLineList, data);
            const createdOrder = await createOrderRequest(orderLineList, data, axiosPrivate);
            if (!createdOrder) {
                throw Error('Otpremnica nije kreirana');
            }
            handleOpenSuccessCreatedOrderModal();
            setApiOrder(createdOrder);
        } catch (validationError) {
            console.error(validationError);
        }
    };

    const handlePrintDocument = () => {
        if (!apiOrder) {
            return;
        }
        requestDocumentPrint(apiOrder._id, axiosPrivate);
    };

    return (
        <Box justifyContent='flex-start'>
            <OrderCreatedModal
                openSuccessCreatedOrderModal={openSuccessCreatedOrderModal}
                handlePrintDocument={handlePrintDocument}
                handleCloseSuccessCreatedOrderModal={handleCloseSuccessCreatedOrderModal}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <AdminHeader title='Nova otpremnica'>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='large'
                        disabled={!isDirty || isSubmitting}
                    >
                        <Save />
                    </Button>
                </AdminHeader>
                <Stack spacing={2} mb={5}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <OrdersActions
                            control={control}
                            errors={errors}
                            auths={orderAuthList}
                            customerType={customerType}
                            onAuthChange={getSingleAuth}
                        />
                        {displayShippingDetailsForm ? (
                            <ShippingDetailsForm control={control} errors={errors} />
                        ) : null}
                        {displayLegalCustomerDetailsForm ? (
                            <LegalCustomerDetailsForm control={control} errors={errors} />
                        ) : null}
                    </Stack>
                    <Stack>
                        <OrderLinesTable
                            orderLines={orderLineList}
                            onRemoveFromOrders={handleRemoveFromOrders}
                            onResetPrice={handleResetPrice}
                            onChangePrice={handleChangePrice}
                            onChangeDiscount={handleChangeDiscount}
                            onChangeDesiredQuantity={handleChangeDesiredQuantity}
                        />
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <ProductsFormController
                                control={control}
                                products={productsList}
                                errors={errors}
                            />
                            <Button
                                type='button'
                                variant='contained'
                                size='large'
                                onClick={addProductToList}
                            >
                                <Add />
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </form>
        </Box>
    );
}

export default OrdersCreate;
