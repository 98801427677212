import { Autocomplete, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { OrderAuth, OrderForm } from '../createOrderForm';

interface AuthsFormControllerInput {
    control: Control<OrderForm>;
    auths: Array<OrderAuth>;
    errors: FieldErrors<OrderForm>;
    disabled?: boolean;
    onAuthChange: (id: string) => void;
}

export const AuthsFormController = ({
    control,
    auths,
    errors,
    disabled,
    onAuthChange,
}: AuthsFormControllerInput) => {
    if (!auths || auths.length === 0) {
        return (
            <Stack direction='row' alignItems='center' spacing={2}>
                <Typography>Ucitavanje korisnika</Typography>
                <CircularProgress color='inherit' />
            </Stack>
        );
    }
    const filterOptions = (options: Array<OrderAuth>, { inputValue }: { inputValue: string }) => {
        const inputKeywords = inputValue.toLowerCase().split(' ');
        return options.filter((option) => {
            const optionKeywords = option.email.toLowerCase().split(' ');
            if (option.name) {
                optionKeywords.push(option.name.toLowerCase());
            }
            return inputKeywords.every((keyword) =>
                optionKeywords.some((optionKeyword) => optionKeyword.includes(keyword)),
            );
        });
    };
    return (
        <Controller
            name='customer'
            control={control}
            defaultValue={auths[0]}
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    fullWidth
                    filterOptions={filterOptions}
                    disableClearable
                    options={auths}
                    disabled={disabled}
                    getOptionLabel={(option) => `[${option.email}] - ${option.name}`}
                    onChange={(_, selectedOption) => {
                        field.onChange(selectedOption);
                        onAuthChange(selectedOption._id);
                    }}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    renderInput={(params) => (
                        <TextField
                            variant='standard'
                            {...params}
                            label='Korisnik'
                            error={!!errors.customer}
                            helperText={errors.customer?.message}
                        />
                    )}
                />
            )}
        />
    );
};
