import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { selectAuth } from '../auth/authSlice';

const LoginProtector = () => {
    const auth = useSelector(selectAuth);
    const location = useLocation();

    return auth.isLoggedIn ? <Navigate to='/' state={{ from: location }} replace /> : <Outlet />;
};

export default LoginProtector;
