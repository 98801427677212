import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { PERSIST, PURGE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import thunk from 'redux-thunk';
import authSlice, { AuthState } from '../admin/auth/authSlice';
import appSlice, { AppState } from './appSlice';

export interface RootState {
    auth: AuthState;
    app: AppState;
}

const authPersistConfig = {
    key: 'auth',
    storage,
};

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice),
    app: appSlice,
});

const rootPersistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk, createStateSyncMiddleware({ blacklist: [PERSIST, PURGE] })],
});

initMessageListener(store);

export const persistor = persistStore(store);
