export interface Category {
    title: string;
    slug: string;
    created: Date;
    createdBy: string;
    updated: Date;
    updatedBy: string;
}

export const getCategoryDefaultUnits = (categorySlug?: string): number => {
    switch (categorySlug) {
        case 'aluminijumske-felne':
        case 'kovane-felne':
            return 4;

        default:
            return 1;
    }
};
