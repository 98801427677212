import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../../api/axios';
import { selectApp, setLoading } from '../../../app/appSlice';
import Link from '../../../app/components/Link';
import { store } from '../../../app/store';
import logo from '../../../logo.svg';
import { loginAuth } from '../authSlice';

function LoginAuth() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const app = useSelector(selectApp);

    const dispatch = store.dispatch;
    const navigate = useNavigate();
    const location = useLocation();

    const sendLoginRequest = async (event: SyntheticEvent) => {
        event.preventDefault();
        dispatch(setLoading(true));
        try {
            const response = await axios.post(
                '/v2/auths/login',
                { email, password, platformType: 'website' },
                {
                    withCredentials: true,
                },
            );
            dispatch(loginAuth(response.data));

            const from = location.state?.from?.pathname || '/';
            navigate(from, { replace: true });
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <img style={{ width: 300 }} src={logo} alt='CPC logo' />
                <Typography sx={{ mt: 4 }} component='h1' variant='h5'>
                    Prijava
                </Typography>
                <Box component='form' onSubmit={sendLoginRequest} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Lozinka'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disabled={app.isLoading}
                    >
                        Prijavi se
                    </Button>
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item>
                            <Link href='#' variant='body2'>
                                Zaboravljena lozinka?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href='/register' variant='body2'>
                                Nemate nalog? Registracija
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default LoginAuth;
