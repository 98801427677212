import Joi from 'joi';
import { countries } from '../../../app/constants';
import { AuthRolesType, AuthStatusesType, authRolesArray, authStatusesArray } from '../types/user';

export interface UpdateAuthForm {
    name: string;
    role: AuthRolesType;
    status: AuthStatusesType;
    verified: boolean;
    // User part
    lastName: string;
    dateOfBirth: Date;
    country: string;
    town: string;
    address: string;
    zip: string;
    phone: string;
    userType: string;
    companyName: string;
    companyNumber: string;
    companyTaxNumber: string;
    companyCountry: string;
    companyTown: string;
    companyAddress: string;
    companyZip: string;
    companyPhone: string;
    discounts: object;
}

export const updateAuthSchema = Joi.object({
    name: Joi.string().trim().min(2).required().messages({
        'string.empty': 'Ime je obavezno polje.',
        'string.min': 'Ime mora sadrzati najmanje 2 karaktera.',
    }),
    role: Joi.string().valid(...authRolesArray),
    status: Joi.string().valid(...authStatusesArray),
    verified: Joi.boolean().default(true),
    country: Joi.string()
        .allow('')
        .valid(...countries),
    lastName: Joi.string().allow(''),
    dateOfBirth: Joi.string().allow(''),
    town: Joi.string().allow(''),
    address: Joi.string().allow(''),
    zip: Joi.string().allow(''),
    phone: Joi.string().allow(''),
    userType: Joi.string().allow(''),
    companyName: Joi.string().allow(''),
    companyNumber: Joi.string().allow(''),
    companyTaxNumber: Joi.string().allow(''),
    companyCountry: Joi.string().allow(''),
    companyAddress: Joi.string().allow(''),
    companyTown: Joi.string().allow(''),
    companyZip: Joi.string().allow(''),
    companyPhone: Joi.string().allow(''),
    discounts: Joi.object().allow({}),
});
