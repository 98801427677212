import { Business, Home, LocationCity, Numbers, Phone, Place } from '@mui/icons-material';
import {
    Card,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListSubheader,
    TextField,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { OrderForm } from './create/createOrderForm';

interface LegalCustomerDetailsFormInput {
    control: Control<OrderForm>;
    errors: FieldErrors<OrderForm>;
    disableUserInput?: boolean;
}

const LegalCustomerDetailsForm = ({
    control,
    errors,
    disableUserInput,
}: LegalCustomerDetailsFormInput) => {
    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component={Card}
            subheader={<ListSubheader>Podaci o firmi</ListSubheader>}
        >
            <Divider />
            <ListItem>
                <ListItemIcon>
                    <Business fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyName'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            {...field}
                            label='Ime firme'
                            fullWidth
                            error={!!errors.companyName}
                            helperText={errors.companyName?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Numbers fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyNumber'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            {...field}
                            label='Maticni broj'
                            fullWidth
                            error={!!errors.companyNumber}
                            helperText={errors.companyNumber?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Numbers fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyTaxNumber'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='PIB'
                            error={!!errors.companyTaxNumber}
                            helperText={errors.companyTaxNumber?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <LocationCity fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyTown'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Grad'
                            error={!!errors.companyTown}
                            helperText={errors.companyTown?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Place fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyZip'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Postanski broj'
                            error={!!errors.companyZip}
                            helperText={errors.companyZip?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Home fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyAddress'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Adresa'
                            error={!!errors.companyAddress}
                            helperText={errors.companyAddress?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Phone fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='companyPhone'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Telefon'
                            error={!!errors.companyPhone}
                            helperText={errors.companyPhone?.message}
                            disabled={disableUserInput}
                        />
                    )}
                />
            </ListItem>
        </List>
    );
};

export default LegalCustomerDetailsForm;
