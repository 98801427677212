import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AppState {
    isLoading: boolean;
}

const initialState: AppState = {
    isLoading: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload;
        },
        resetAppState: () => initialState,
    },
});

export const selectApp = (state: RootState) => state.app;

export const { setLoading, resetAppState } = appSlice.actions;

export default appSlice.reducer;
