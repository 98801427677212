export const isEmptyObject = (obj: object): boolean => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};
export const removeFalsyValueFromObject = (
    object: any,
    excludeZero = true,
    excludeEmptyString = true,
) => {
    for (const key in object) {
        if (
            object[key] === undefined ||
            object[key] === null ||
            (excludeEmptyString && object[key] === '') ||
            (excludeZero && object[key] === 0) ||
            isEmptyObject(object[key])
        ) {
            delete object[key];
        }
    }
    return object;
};
