import { CalendarMonthOutlined, Discount, Euro, Person } from '@mui/icons-material';
import {
    Card,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListSubheader,
    Stack,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { APIOrder } from '../../app/entities/order';
import { buildPrice } from './OrdersTable';
import { OrderCustomerType } from './enums';

const OrderInformation = (props: { order: APIOrder }) => {
    const order = props.order;
    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component={Card}
            subheader={<ListSubheader>Informacije</ListSubheader>}
        >
            <Divider />
            <ListItem>
                <ListItemIcon>
                    <Person fontSize='medium' />
                </ListItemIcon>
                <Typography>{buildCustomerInfo(order)}</Typography>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Euro fontSize='medium' />
                </ListItemIcon>
                <Stack direction='row' spacing={1}>
                    {buildPrice(order.totalPrice, order.afterDiscountPrice)}
                    <Typography>(Placeno: {order.paidAmount} EUR)</Typography>
                </Stack>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Discount fontSize='medium' />
                </ListItemIcon>
                <Typography>Popust {order.totalDiscount}%</Typography>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CalendarMonthOutlined fontSize='medium' />
                </ListItemIcon>
                <Typography>{moment(order.created).locale('sr').format('DD MMM, YYYY')}</Typography>
            </ListItem>
        </List>
    );
};

const buildCustomerInfo = (order: APIOrder) => {
    if (order.customerType === OrderCustomerType.REGULAR && order.customer) {
        return `${order?.customer.name} (${order?.customer.email})`;
    }
    let concatCustomerInfo = '';
    if (order.customerName) {
        concatCustomerInfo = order.customerName;
    }
    if (order.customerEmail) {
        concatCustomerInfo += ` (${order.customerEmail})`;
    }
    return concatCustomerInfo;
};

export default OrderInformation;
