import { Box, CircularProgress, Typography } from '@mui/material';

interface UploadedPercentageComponentProps {
    percentage: number;
}

export const UploadedPercentageComponent = ({ percentage }: UploadedPercentageComponentProps) => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography>Uploading...</Typography>
            <Box p={1}>
                <CircularProgress variant='determinate' value={Math.floor(percentage)} />
            </Box>
            <Typography>{Math.floor(percentage)}/100%</Typography>
        </Box>
    );
};
