import { Home, LocationCity, Phone, Place, Public } from '@mui/icons-material';
import {
    Autocomplete,
    Card,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListSubheader,
    TextField,
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { countries } from '../../../app/constants';
import { UpdateAuthForm } from '../forms/updateAuthForm';

interface ShippingDetailsFormInput {
    control: Control<UpdateAuthForm>;
    errors: FieldErrors<UpdateAuthForm>;
}

const ShippingDetailsForm = ({ control, errors }: ShippingDetailsFormInput) => {
    return (
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component={Card}
            subheader={<ListSubheader>Detalji za slanje</ListSubheader>}
        >
            <Divider />
            <ListItem>
                <ListItemIcon>
                    <Public fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='country'
                    control={control}
                    defaultValue={countries[0]}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            fullWidth
                            disableClearable
                            options={countries}
                            onChange={(_, selectedOption) => {
                                field.onChange(selectedOption);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='standard'
                                    {...params}
                                    label='Drzava'
                                    error={!!errors.country}
                                    helperText={errors.country?.message}
                                />
                            )}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <LocationCity fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='town'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Grad'
                            error={!!errors.town}
                            helperText={errors.town?.message}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Place fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='zip'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Postanski broj'
                            error={!!errors.zip}
                            helperText={errors.zip?.message}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Home fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='address'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Adresa'
                            error={!!errors.address}
                            helperText={errors.address?.message}
                        />
                    )}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <Phone fontSize='medium' />
                </ListItemIcon>
                <Controller
                    name='phone'
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                        <TextField
                            variant='standard'
                            fullWidth
                            {...field}
                            label='Telefon'
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                    )}
                />
            </ListItem>
        </List>
    );
};

export default ShippingDetailsForm;
