import Joi from 'joi';
import {
    AuthRolesType,
    AuthStatusesType,
    PASSWORD_REGEX,
    authRolesArray,
    authStatusesArray,
} from '../types/user';

export interface CreateAuthForm {
    name: string;
    email: string;
    password: string;
    repeatPassword: string;
    role: AuthRolesType;
    status: AuthStatusesType;
    verified: boolean;
}

export const createAuthSchema = Joi.object({
    name: Joi.string().trim().min(2).required().messages({
        'string.empty': 'Ime je obavezno polje.',
        'string.min': 'Ime mora sadrzati najmanje 2 karaktera.',
    }),
    email: Joi.string().email({ tlds: false }).trim().lowercase().required().messages({
        'string.email': 'Email nije validan.',
        'string.empty': 'Email je obavezno polje.',
    }),
    password: Joi.string().pattern(new RegExp(PASSWORD_REGEX)).required().messages({
        'string.empty': 'Lozinka je obavezno polje.',
        'string.pattern.base':
            'Lozinka mora sadrzati najmanje 8 karaktera, od toga barem jedno slovo i jedan broj.',
    }),
    repeatPassword: Joi.string().valid(Joi.ref('password')).required().messages({
        'any.only': 'Lozinke se ne podudaraju.',
    }),
    role: Joi.string().valid(...authRolesArray),
    status: Joi.string().valid(...authStatusesArray),
    verified: Joi.boolean().default(true),
}).with('password', 'repeatPassword');
