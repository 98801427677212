import { CloudUpload, Delete } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { isAxiosError, isCancel } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../api/axios';
import { setLoading } from '../../app/appSlice';
import { VisuallyHiddenInput } from '../../app/components/styles';
import { Carousel } from '../../app/entities/carousel';
import { store } from '../../app/store';
import useAxiosMultipartFormData from '../../hooks/useAxiosMultipartFormData';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminHeader from '../components/AdminHeader';
import CreateCarouselModal from './CreateCarouselModal';

function MainCarouselLandingPage() {
    const [file, setFile] = React.useState<File>();
    const [preview, setPreview] = React.useState('');
    const [percentage, setPercentage] = React.useState(0);
    const [isImageUploaded, setIsImageUploaded] = React.useState(false);
    const [newImageName, setNewImageName] = React.useState('');
    const [carousels, setCarousels] = React.useState<Array<Carousel>>([]);
    const [openCreateCarouselModal, setOpenCreateCarouselModal] = React.useState(false);

    const axiosPrivateFormData = useAxiosMultipartFormData();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = store.dispatch;

    const handleOpenCarouselModal = () => setOpenCreateCarouselModal(true);
    const handleCloseCarouselModal = () => setOpenCreateCarouselModal(false);

    const deleteImage = async (imageName: string) => {
        dispatch(setLoading(true));
        try {
            const response = await axiosPrivate.delete(`/v2/images/${imageName}`);
            console.log(response);
        } catch (error) {
            console.error(error);
        } finally {
            setIsImageUploaded(false);
            dispatch(setLoading(false));
        }
    };

    async function handleCarouselCreate(fileName: string, title: string, description: string) {
        dispatch(setLoading(true));
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.post(
                '/v2/carousels',
                { fileName, title, description },
                { signal: abortController.signal },
            );

            if (response.data.carousel) {
                const carousel: Carousel = response.data.carousel;
                setCarousels((prevCarousels) => [...prevCarousels, carousel]);
                return console.log(response.data.carousel);
            }
            console.log('No message');
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        } finally {
            setIsImageUploaded(false);
            dispatch(setLoading(false));
        }
    }

    const handleCarouselDelete = async (carousel: Carousel) => {
        dispatch(setLoading(true));
        try {
            const response = await axiosPrivate.delete(`/v2/carousels/${carousel._id}`);
            console.log(response);
            setCarousels((prevCarousels) =>
                prevCarousels.filter((item) => item._id !== carousel._id),
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsImageUploaded(false);
            dispatch(setLoading(false));
        }
    };

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadCarousels() {
            dispatch(setLoading(true));
            try {
                const response = await axios.get('/v2/carousels', {
                    signal: abortController.signal,
                });
                if (response.data.carousels && isMounted) {
                    setCarousels(response.data.carousels);
                }
            } catch (error) {
                if (isCancel(error)) {
                    return console.error('Request canceled', error);
                }
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                dispatch(setLoading(false));
                isMounted = false;
            }
        }
        loadCarousels();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, []);

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function uploadImage(file: File) {
            dispatch(setLoading(true));
            const uuid = uuidv4();
            const extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
            const newFileName = uuid + extension;
            setNewImageName(newFileName);
            try {
                const formData = new FormData();
                formData.append('image', file, newFileName);
                const response = await axiosPrivateFormData.post('/v2/images', formData, {
                    signal: abortController.signal,
                    onUploadProgress: (progress) => {
                        const { total, loaded } = progress;
                        if (!total) {
                            return;
                        }
                        const totalSizeInMB = total / (1024 * 1024);
                        const loadedSizeInMB = loaded / (1024 * 1024);
                        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
                        setPercentage(uploadPercentage);
                    },
                });

                if (response.data.message && isMounted) {
                    setIsImageUploaded(true);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    if (error.response?.status === 500) {
                        return enqueueSnackbar('Greska: Format fotografije nije podrzan.', {
                            variant: 'error',
                        });
                    }
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                dispatch(setLoading(false));
                isMounted = false;
            }
        }
        if (!file) {
            return;
        }
        const imagePreview = URL.createObjectURL(file);
        setPreview(imagePreview);
        uploadImage(file);
        return () => {
            isMounted = false;
            abortController.abort();
            URL.revokeObjectURL(imagePreview);
        };
    }, [file]);

    React.useEffect(() => {
        if (!preview) {
            return;
        }
        handleOpenCarouselModal();
    }, [preview]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.target.files) {
            return;
        }
        const file = event.target.files[0];
        setFile(file);
    };

    return (
        <Box justifyContent='flex-start'>
            <CreateCarouselModal
                openCreateCarouselModal={openCreateCarouselModal}
                newImageName={newImageName}
                percentage={percentage}
                isImageUploaded={isImageUploaded}
                previewImage={preview}
                deleteImage={deleteImage}
                handleCloseCarouselModal={handleCloseCarouselModal}
                handleCarouselCreate={handleCarouselCreate}
            />
            <AdminHeader title='Slike na pocetnoj'>
                <Button component='label' variant='contained' startIcon={<CloudUpload />}>
                    Dodaj sliku
                    <VisuallyHiddenInput type='file' name='image' onChange={handleImageUpload} />
                </Button>
            </AdminHeader>
            {carousels && carousels.length > 0 ? (
                <Grid container spacing={2} mb={2}>
                    {carousels.map((item) => {
                        return (
                            <CarouselComponent
                                key={item._id}
                                item={item}
                                handleCarouselDelete={handleCarouselDelete}
                            />
                        );
                    })}
                </Grid>
            ) : (
                <Typography variant='h4' gutterBottom>
                    Nije dodata nijedna slika, prikazace se podrazumevane
                </Typography>
            )}
        </Box>
    );
}

interface CarouselComponentInput {
    item: Carousel;
    handleCarouselDelete: (carousel: Carousel) => void;
}

const CarouselComponent = ({ item, handleCarouselDelete }: CarouselComponentInput) => {
    return (
        <Grid item xs={12} sm={6}>
            <Stack
                gap={1}
                sx={{
                    borderRadius: 2,
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
            >
                <Box position='relative'>
                    <Box
                        bgcolor='transparent'
                        position='absolute'
                        top={0}
                        right={0}
                        overflow='hidden'
                        zIndex={10}
                        sx={{
                            transition: 'opacity 0.3s',
                            opacity: { xs: 1, sm: 0.7 },
                            '&:hover': { opacity: 1 },
                        }}
                    >
                        <Stack justifyContent='space-between'>
                            <Button
                                variant='contained'
                                color='error'
                                onClick={() => handleCarouselDelete(item)}
                            >
                                <Delete sx={{ fontSize: 24 }} />
                            </Button>
                            {/* <Button
                                variant='contained'
                                color='info'
                                onClick={() => console.log('To be implemented')}
                            >
                                <Edit sx={{ fontSize: 24 }} />
                            </Button> */}
                        </Stack>
                    </Box>
                    <img
                        style={{
                            aspectRatio: '1920 / 682',
                            borderRadius: 5,
                            objectFit: 'cover',
                            display: 'block',
                        }}
                        src={`${process.env.REACT_APP_SERVER_URL}/uploads/${item.image}`}
                        loading='lazy'
                        alt={item.title}
                        width='100%'
                    />
                </Box>
                <Typography>{item.title}</Typography>
                <Typography>{item.description}</Typography>
            </Stack>
        </Grid>
    );
};

export default MainCarouselLandingPage;
