import { Box, Container, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import axios from '../../../api/axios';
import { setLoading } from '../../../app/appSlice';
import { store } from '../../../app/store';
import { ApiPosts } from '../postsSlice';

function PostsLandingPage() {
    const [posts, setPosts] = React.useState<ApiPosts[]>([]);
    const dispatch = store.dispatch;

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function fetchData() {
            dispatch(setLoading(true));
            try {
                const response = await axios.get('/posts', {
                    signal: abortController.signal,
                });

                if (response.data.posts && isMounted) {
                    setPosts(response.data.posts);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                dispatch(setLoading(false));
                isMounted = false;
            }
        }
        fetchData();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, []);

    return (
        <Container component='main'>
            <Box>
                {posts.map((post) => (
                    <Typography key={post._id}>{post.title}</Typography>
                ))}
            </Box>
        </Container>
    );
}

export default PostsLandingPage;
