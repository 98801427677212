import { Add, FilterAlt } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { RefObject, createRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonLink from '../../app/components/ButtonLink';
import { APIOrder } from '../../app/entities/order';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import AdminHeader from '../components/AdminHeader';
import SearchComponent from '../components/SearchComponent';
import FiltersModal from './FilterModal';
import OrdersTable from './OrdersTable';
import { UpdateOrderAPIInput } from './create/createOrderForm';
import { defaultOrderStatusesList } from './enums';
import { requestDocumentPrint } from './repositories/APIOrdersRepository';

function OrdersLandingPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const page = Number(queryParams.get('page')) ?? 0;
    const search = queryParams.get('search') ?? '';
    const isPaidTrue = queryParams.get('isPaidTrue') ?? '';
    const isPaidFalse = queryParams.get('isPaidFalse') ?? '';
    const status = queryParams.get('status') ?? defaultOrderStatusesList;

    const tableRef: RefObject<HTMLTableElement> = createRef<HTMLTableElement>();
    const [orders, setOrders] = React.useState<APIOrder[]>([]);
    const [ordersCount, setOrdersCount] = React.useState(0);
    const [openFiltersModal, setOpenFiltersModal] = React.useState(false);
    const axiosPrivate = useAxiosPrivate();

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadOrders() {
            try {
                const response = await axiosPrivate.get('/v2/orders', {
                    params: queryParams,
                    signal: abortController.signal,
                });

                if (response && response.data.orders && isMounted) {
                    setOrders(response.data.orders);
                    setOrdersCount(response.data.ordersCount);
                }
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        loadOrders();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [page, search, status, isPaidTrue, isPaidFalse]);

    const handleChangePage = (event: unknown, newPage: number) => {
        queryParams.set('page', newPage.toString());
        tableRef.current && tableRef.current.scrollIntoView();
        navigate({ search: queryParams.toString() });
    };

    const handleSetFilters = (values: Array<string>, isPaidTrue: boolean, isPaidFalse: boolean) => {
        queryParams.delete('status');
        values.map((value) => queryParams.append('status', value));
        queryParams.set('isPaidTrue', String(isPaidTrue));
        queryParams.set('isPaidFalse', String(isPaidFalse));
        navigate({ search: queryParams.toString() });
    };

    const deleteOrder = async (orderId: string) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.delete(`/v2/orders/${orderId}`, {
                signal: abortController.signal,
            });

            if (response && response.status === 204) {
                enqueueSnackbar('Uspesno obrisana otpremnica', { variant: 'success' });
                setOrders((prevOrders) => prevOrders.filter((order) => order._id !== orderId));
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const handleUpdateOrder = async (updateOrderInput: Partial<UpdateOrderAPIInput>) => {
        const abortController = new AbortController();
        try {
            const response = await axiosPrivate.patch('/v2/orders', updateOrderInput, {
                signal: abortController.signal,
            });

            if (response && response.data.updatedOrder) {
                const updatedOrder = response.data.updatedOrder;
                setOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order._id === updatedOrder._id ? updatedOrder : order,
                    ),
                );
                enqueueSnackbar('Uspesno azurirana otpremnica', { variant: 'success' });
            }
        } catch (error) {
            if (isAxiosError(error)) {
                enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                return console.error(error?.response?.data?.message);
            }
            console.error(error);
        }
    };

    const handlePrintDocument = (orderId: string) => {
        requestDocumentPrint(orderId, axiosPrivate);
    };

    const handleOpenFiltersModal = () => {
        setOpenFiltersModal(true);
    };

    const handleCloseFiltersModal = () => {
        setOpenFiltersModal(false);
    };

    return (
        <Box justifyContent='flex-start'>
            <FiltersModal
                openFiltersModal={openFiltersModal}
                onFilterApplied={handleSetFilters}
                handleCloseFilterModal={handleCloseFiltersModal}
            />
            <AdminHeader title='Otpremnice'>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Stack direction='row' spacing={1} alignItems='flex-end'>
                        <SearchComponent queryParams={queryParams} searchValue={search} />
                        <Box>
                            <IconButton onClick={handleOpenFiltersModal}>
                                <FilterAlt />
                            </IconButton>
                        </Box>
                    </Stack>
                    <ButtonLink href='./create'>
                        <Add />
                    </ButtonLink>
                </Stack>
            </AdminHeader>
            <OrdersTable
                orders={orders}
                ordersCount={ordersCount}
                page={page}
                tableRef={tableRef}
                handleChangePage={handleChangePage}
                deleteOrder={deleteOrder}
                printDocument={handlePrintDocument}
                handleUpdateOrder={handleUpdateOrder}
            />
        </Box>
    );
}

export default OrdersLandingPage;
