import { joiResolver } from '@hookform/resolvers/joi';
import { Save } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AdminHeader from '../../components/AdminHeader';
import { CreateAuthForm, createAuthSchema } from '../forms/createAuthForm';
import { createAuthRequest } from '../repositories/APIAuthsRepository';
import AuthCreatedModal from './AuthCreatedModal';
import AuthDetailsCreateForm from './AuthDetailsCreateForm';

function AuthCreate() {
    const [openSuccessCreatedAuthModal, setOpenSuccessCreatedAuthModal] =
        React.useState<boolean>(false);
    const [userId, setUserId] = React.useState<string | undefined>();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const {
        control,
        formState: { errors, isDirty, isSubmitting },
        getValues,
        handleSubmit,
        clearErrors,
        reset,
    } = useForm<CreateAuthForm>({
        resolver: joiResolver(createAuthSchema),
        mode: 'onBlur',
    });

    const handleOpenSuccessCreatedAuthModal = () => {
        setOpenSuccessCreatedAuthModal(true);
    };

    const handleCloseSuccessCreatedAuthModal = () => {
        setOpenSuccessCreatedAuthModal(false);
        reset();
    };

    const handleEditCurrentUser = (id?: string) => {
        if (!id) {
            return;
        }
        navigate(`/users/edit/${id}`);
    };

    const onSubmit = async (data: CreateAuthForm) => {
        try {
            const createdAuth = await createAuthRequest(data, axiosPrivate);
            if (!createdAuth) {
                throw Error('Korisnik nije kreiran');
            }
            setUserId(createdAuth._id);
            handleOpenSuccessCreatedAuthModal();
        } catch (validationError) {
            console.error(validationError);
        }
    };

    return (
        <Box justifyContent='flex-start'>
            <AuthCreatedModal
                id={userId}
                openSuccessCreatedOrderModal={openSuccessCreatedAuthModal}
                handleEditCurrentUser={handleEditCurrentUser}
                handleCloseSuccessCreatedOrderModal={handleCloseSuccessCreatedAuthModal}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <AdminHeader title='Novi korisnik'>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={!isDirty || isSubmitting}
                        >
                            <Save />
                        </Button>
                    </Stack>
                </AdminHeader>
                <AuthDetailsCreateForm control={control} errors={errors} />
            </form>
        </Box>
    );
}

export default AuthCreate;
