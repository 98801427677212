import { Dialog, DialogContent } from '@mui/material';
import { imagesSizes } from '../constants';

interface ImageModalInput {
    imageId?: string;
    imageSize?: string;
    openImageModal: boolean;
    handleCloseImageModal: () => void;
}

function ImageModal({
    imageId,
    imageSize = imagesSizes.ORIGINAL,
    openImageModal,
    handleCloseImageModal,
}: ImageModalInput) {
    const imageURL = getImageURL(imageId, imageSize);
    return (
        <Dialog open={openImageModal} onClose={handleCloseImageModal} sx={{ padding: 0 }}>
            <DialogContent>
                <img src={imageURL} width='100%' />
            </DialogContent>
        </Dialog>
    );
}

export const getImageURL = (imageId?: string, imageSize = imagesSizes.THUMBNAIL) => {
    if (!imageId) {
        return `${process.env.REACT_APP_SERVER_URL}/images/icon.svg`;
    }
    return `${process.env.REACT_APP_SERVER_URL}/uploads/${imageSize}${imageId}`;
};

export const getImageId = (images?: Array<string>) => {
    if (!images || images.length === 0) {
        return '';
    }
    return images[0];
};

export default ImageModal;
