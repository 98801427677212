import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectApp } from '../appSlice';

function Loader() {
    const { isLoading } = useSelector(selectApp);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color='inherit' />
        </Backdrop>
    );
}

export default Loader;
