import { Close, Search } from '@mui/icons-material';
import {
    Box,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '../../app/components/Link';
import theme from '../../theme';

interface SearchComponentInput {
    searchValue: string;
    inputLabel?: string;
    queryParams: URLSearchParams;
    searchCorrection?: string;
    correctedSearchQuery?: string;
}

const SearchComponent = ({
    searchValue,
    inputLabel = 'Pretraga...',
    queryParams,
    searchCorrection,
    correctedSearchQuery,
}: SearchComponentInput) => {
    const navigate = useNavigate();
    const [searchInput, setSearchInput] = React.useState(searchValue);

    const handleSearch = (search: string) => {
        if (search === '') {
            return;
        }
        queryParams.delete('page');
        queryParams.set('search', search);
        navigate({ search: queryParams.toString() });
    };

    const handleClearSearch = () => {
        setSearchInput('');
        queryParams.delete('page');
        queryParams.delete('search');
        navigate({ search: queryParams.toString() });
    };

    const handleCorrectSearchQuery = () => {
        if (!searchCorrection) {
            return;
        }
        setSearchInput(searchCorrection);
    };

    const handleMouseDownSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Stack flex='auto' alignItems='stretch'>
            <FormControl variant='standard' fullWidth>
                <InputLabel htmlFor='search'>{inputLabel}</InputLabel>
                <Input
                    id='search'
                    fullWidth
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='handle search'
                                onClick={() => handleSearch(searchInput)}
                                onMouseDown={handleMouseDownSearch}
                                edge='end'
                            >
                                <Search />
                            </IconButton>
                            <IconButton
                                onClick={handleClearSearch}
                                onMouseDown={handleMouseDownSearch}
                                edge='end'
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    }
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSearch(searchInput);
                    }}
                />
            </FormControl>
            {searchCorrection ? (
                <Box
                    display='flex'
                    flexDirection='row'
                    sx={{ justifyContent: { xs: 'center', sm: 'flex-end' } }}
                    marginTop={2}
                >
                    <Typography gutterBottom>Da li ste mislili&nbsp;</Typography>
                    <Link
                        variant='button'
                        color={theme.palette.info.main}
                        onClick={handleCorrectSearchQuery}
                        href={`?${correctedSearchQuery}`}
                    >
                        {searchCorrection}
                    </Link>
                    <Typography gutterBottom>?</Typography>
                </Box>
            ) : null}
        </Stack>
    );
};

export default SearchComponent;
