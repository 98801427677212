import { Box, Container, Typography } from '@mui/material';
import ButtonLink from './ButtonLink';

const Missing = () => {
    return (
        <Container sx={{ mt: 10 }} maxWidth='sm'>
            <Typography variant='h1'>Oops!</Typography>
            <Typography variant='h5'>Stranica nije pronadjena!</Typography>
            <Box display='flex' sx={{ mt: 6 }} justifyContent='space-between'>
                <ButtonLink href='..'>Prethodna strana</ButtonLink>
                <ButtonLink href='/'>Pocetna strana</ButtonLink>
            </Box>
        </Container>
    );
};

export default Missing;
