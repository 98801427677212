import {
    BookmarkAddOutlined,
    Collections,
    GroupOutlined,
    HandymanOutlined,
    Inbox,
    InsertDriveFileOutlined,
    LocalShippingOutlined,
    MultipleStopOutlined,
    ReceiptOutlined,
    ShoppingCartOutlined,
    ViewAgendaOutlined,
} from '@mui/icons-material';
import React from 'react';
import AuthLandingPage from '../auths/AuthLandingPage';
import DashboardLandingPage from '../dashboard/DashboardLandingPage';
import PostsLandingPage from '../posts/view/PostsLandingPage';

type MenuItems = {
    label: string;
    path: string;
    icon?: React.ReactNode | null;
    component?: React.ReactNode | null;
    isInNavigation?: boolean;
};

export const getMainNavigation: (role: any) => MenuItems[] = (role: any) => {
    return [
        {
            label: 'Dashboard',
            path: '/',
            icon: <Inbox />,
            component: <DashboardLandingPage />,
            isInNavigation: true,
        },
        {
            label: 'Novosti',
            path: '/novosti',
            icon: <InsertDriveFileOutlined />,
            component: <PostsLandingPage />,
            isInNavigation: false,
        },
        {
            label: 'Proizvodi',
            path: '/products',
            icon: <ShoppingCartOutlined />,
            isInNavigation: false,
        },
        {
            label: 'Otpremnice',
            path: '/orders',
            icon: <ReceiptOutlined />,
            isInNavigation: true,
        },
        {
            label: 'Narucivanja',
            path: '/online-orders',
            icon: <LocalShippingOutlined />,
            isInNavigation: false,
        },
        {
            label: 'Ulaz - Izlaz',
            path: '/ulaz-izlaz',
            icon: <MultipleStopOutlined />,
            isInNavigation: false,
        },
        { label: 'Galerija', path: '/galerija', icon: <HandymanOutlined />, isInNavigation: false },
        {
            label: 'Slike na pocetnoj',
            path: '/main-carousel',
            icon: <Collections />,
            isInNavigation: true,
        },
        {
            label: 'Korisnici',
            path: '/users',
            icon: <GroupOutlined />,
            component: <AuthLandingPage />,
            isInNavigation: true,
        },
        {
            label: 'Brendovi',
            path: '/brendovi',
            icon: <ViewAgendaOutlined />,
            isInNavigation: false,
        },
        {
            label: 'Potkategorije',
            path: '/potkategorije',
            icon: <BookmarkAddOutlined />,
            isInNavigation: false,
        },
    ];
};
