import { joiResolver } from '@hookform/resolvers/joi';
import { Save } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { isAxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import AdminHeader from '../../components/AdminHeader';
import { UpdateAuthForm, updateAuthSchema } from '../forms/updateAuthForm';
import { updateAuthRequest } from '../repositories/APIAuthsRepository';
import { Auth, User } from '../types/user';
import AuthDetailsEditForm from './AuthDetailsEditForm';
import LegalCustomerDetailsForm from './LegalCustomerDetailsForm';
import ShippingDetailsForm from './ShippingDetailsForm';

function AuthEdit() {
    const [auth, setAuth] = React.useState<Auth>();
    const [user, setUser] = React.useState<User>();
    const { id } = useParams();
    const axiosPrivate = useAxiosPrivate();

    const {
        control,
        formState: { errors, isDirty, isSubmitting, dirtyFields },
        getValues,
        handleSubmit,
        clearErrors,
        reset,
    } = useForm<UpdateAuthForm>({
        resolver: joiResolver(updateAuthSchema),
        mode: 'onBlur',
    });

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.error('Form has errors:', errors);
        }
    }, [errors]);

    React.useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();
        async function loadAuth() {
            try {
                const response = await axiosPrivate.get(`/v2/auths/${id}`, {
                    signal: abortController.signal,
                });

                if (!response || !response.data.auth || !response.data.user || !isMounted) {
                    return;
                }
                const auth: Auth = response.data.auth;
                const user: User = response.data.user;
                const updateAuthForm: Partial<UpdateAuthForm> = {
                    name: auth.name,
                    role: auth.role,
                    status: auth.status,
                    verified: auth.verified,
                    // User part
                    lastName: user.lastName,
                    dateOfBirth: user.dateOfBirth,
                    country: user.country,
                    town: user.town,
                    address: user.address,
                    zip: user.zip,
                    phone: user.phone,
                    userType: user.userType,
                    companyName: user.companyName,
                    companyNumber: user.companyNumber,
                    companyTaxNumber: user.companyTaxNumber,
                    companyCountry: user.companyCountry,
                    companyTown: user.companyTown,
                    companyAddress: user.companyAddress,
                    companyZip: user.companyZip,
                    companyPhone: user.companyPhone,
                    discounts: user.discounts,
                };
                reset(updateAuthForm);
                setAuth(auth);
                setUser(user);
            } catch (error) {
                if (isAxiosError(error)) {
                    enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
                    return console.error(error?.response?.data?.message);
                }
                console.error(error);
            } finally {
                isMounted = false;
            }
        }
        loadAuth();
        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, []);

    if (!auth) {
        return (
            <AdminHeader title='Izmeni korisnika'>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='large'
                        disabled={!isDirty || isSubmitting || !id}
                    >
                        <Save />
                    </Button>
                </Stack>
            </AdminHeader>
        );
    }

    const onSubmit = async (data: UpdateAuthForm) => {
        console.log(data);
        if (!id || !user?._id) {
            return;
        }
        try {
            const createdAuth = await updateAuthRequest(id, user._id, data, axiosPrivate);
            if (!createdAuth) {
                throw Error('Korisnik nije azuriran');
            }
            // handleOpenSuccessCreatedOrderModal();
        } catch (validationError) {
            console.error(validationError);
        }
    };

    return (
        <Box justifyContent='flex-start'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <AdminHeader title='Izmeni korisnika'>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='large'
                            disabled={!isDirty || isSubmitting || !id}
                        >
                            <Save />
                        </Button>
                    </Stack>
                </AdminHeader>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <AuthDetailsEditForm control={control} errors={errors} email={auth.email} />
                    <ShippingDetailsForm control={control} errors={errors} />
                    <LegalCustomerDetailsForm control={control} errors={errors} />
                </Stack>
            </form>
        </Box>
    );
}

export default AuthEdit;
