import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

interface OrderCompletedModalInput {
    openCompletedOrderModal: boolean;
    handleUpdateOrder: (shouldRemoveFromBalance: boolean) => void;
    handleCloseCompletedOrderModal: () => void;
}

function OrderCompletedModal({
    openCompletedOrderModal,
    handleUpdateOrder,
    handleCloseCompletedOrderModal,
}: OrderCompletedModalInput) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleShouldRemoveFromBalance = (shouldRemoveFromBalance: boolean) => {
        handleUpdateOrder(shouldRemoveFromBalance);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openCompletedOrderModal}
            onClose={handleCloseCompletedOrderModal}
        >
            <DialogTitle>Pre nego sto zavrsite!</DialogTitle>
            <DialogContent>
                <Typography>Da li zelite da skinete proizvode sa stanja?</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => handleShouldRemoveFromBalance(false)}>
                    Ne
                </Button>
                <Button variant='outlined' onClick={handleCloseCompletedOrderModal}>
                    Odustani
                </Button>
                <Button variant='contained' onClick={() => handleShouldRemoveFromBalance(true)}>
                    Da
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OrderCompletedModal;
