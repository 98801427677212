import Joi from 'joi';
import { countries } from '../../../app/constants';
import { Category } from '../../../app/entities/category';
import { Entity } from '../../../app/entities/entity';
import { ApiAuth } from '../../auth/authSlice';
import { OrderCustomerType, OrderPayingMethod, OrderShippingMethod, OrderStatuses } from '../enums';

export const createOrderLineSchema = Joi.object({
    _id: Joi.string().allow(''),
    product: Joi.object(),
    customer: Joi.object(),
    quantity: Joi.number(),
    orderType: Joi.string(),
    price: Joi.number(),
    priceUnit: Joi.string(),
    discount: Joi.number(),
    afterDiscountPrice: Joi.number(),
    order: Joi.string(),
    created: Joi.date(),
    createdBy: Joi.string(),
    // name: Joi.string().allow(''),
    // country: Joi.string()
    //     .allow('')
    //     .valid(...countries),
    // town: Joi.string().allow(''),
    // address: Joi.string().allow(''),
    // zip: Joi.string().allow(''),
    // phone: Joi.string().allow(''),
    // email: Joi.string()
    //     .allow('')
    //     .email({ tlds: { allow: false } }),
    // companyName: Joi.string().allow(''),
    // companyNumber: Joi.string().allow(''),
    // companyTaxNumber: Joi.string().allow(''),
    // companyAddress: Joi.string().allow(''),
    // companyTown: Joi.string().allow(''),
    // companyZip: Joi.string().allow(''),
    // companyPhone: Joi.string().allow(''),
    name: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    country: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string()
            .valid(...countries)
            .required(),
    }),
    town: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    address: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    zip: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    phone: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    email: Joi.when('shippingMethod', {
        is: Joi.string().valid(OrderShippingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string()
            .email({ tlds: { allow: false } })
            .allow(''),
    }),
    companyName: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyNumber: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyTaxNumber: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyAddress: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyTown: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyZip: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    companyPhone: Joi.when('payingMethod', {
        is: Joi.string().valid(OrderPayingMethod.STANDARD),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    isPaid: Joi.boolean(),
    isAssemblyRequired: Joi.boolean(),
    payingMethod: Joi.string().valid(...Object.values(OrderPayingMethod)),
    shippingMethod: Joi.string().valid(...Object.values(OrderShippingMethod)),
    status: Joi.string().valid(...Object.values(OrderStatuses)),
    customerType: Joi.string().valid(...Object.values(OrderCustomerType)),
    customerName: Joi.when('customerType', {
        is: Joi.string().valid(OrderCustomerType.REGULAR),
        then: Joi.string().allow(''),
        otherwise: Joi.string().required(),
    }),
    customerEmail: Joi.string()
        .email({ tlds: { allow: false } })
        .allow(''),
    shouldRemoveFromBalance: Joi.boolean(),
    // customerEmail: Joi.when('customerType', {
    //     is: Joi.string().valid(OrderCustomerType.REGULAR),
    //     then: Joi.string()
    //         .email({ tlds: { allow: false } })
    //         .allow(''),
    //     otherwise: Joi.string().required(),
    // }),
});

export interface OrderForm extends Entity {
    product: APIOrderProduct;
    orderType: string;
    name: string;
    country: string;
    town: string;
    address: string;
    zip: string;
    phone: string;
    email: string;
    companyName: string;
    companyNumber: string;
    companyTaxNumber: string;
    companyAddress: string;
    companyTown: string;
    companyZip: string;
    companyPhone: string;
    isPaid: boolean;
    isAssemblyRequired: boolean;
    payingMethod: string;
    shippingMethod: string;
    status: string;
    customerType: string;
    customer: OrderAuth;
    customerName: string;
    customerEmail: string;
    shouldRemoveFromBalance: boolean;
}

export interface APIOrderProduct extends Entity {
    uniqueId?: string;
    title: string;
    quantity: number;
}

export interface OrderLine {
    productId: string;
    orderLineId?: string;
    uniqueId?: string;
    title: string;
    quantity: number;
    originalPrice: number;
    price: number;
    optionalPrice?: string;
    priceUnit?: string;
    category: Category;
    subCategory?: OrderCategory;
    status?: string;
    images?: Array<string>;
    desiredQuantity: number;
    discountPercentage: number;
}

export interface OrderCategory extends Entity {
    slug: string;
    title?: string;
}

export interface OrderAuth extends Entity {
    email: string;
    name?: string;
}

export interface CreateOrderAPIInput {
    isPaid: boolean;
    isAssemblyRequired: boolean;
    payingMethod: string;
    shippingMethod: string;
    customerType: string;
    orderType: string;
    customerName?: string;
    customerEmail?: string;
    customerId?: string;
    status: string;
    // Shipping details
    name?: string;
    country?: string;
    town?: string;
    address?: string;
    zip?: string;
    phone?: string;
    email?: string;
    // Legal customer details
    companyName?: string;
    companyNumber?: string;
    companyTaxNumber?: string;
    companyAddress?: string;
    companyTown?: string;
    companyZip?: string;
    companyPhone?: string;
    // Order lines
    orders?: Array<OrderLineAPIInput>;
}

export interface OrderLineAPIInput {
    orderLineId?: string;
    productId: string;
    price: number;
    discountPercentage: number;
    desiredQuantity: number;
    pricePerOrderLine: number;
}

export interface UpdateOrderAPIInput extends CreateOrderAPIInput {
    id: string;
    removedOrderLineList?: Array<string>;
    shouldRemoveFromBalance?: boolean;
}

export const getOrderAuth = (customer?: ApiAuth): OrderAuth | undefined => {
    if (!customer) {
        return;
    }
    return {
        _id: customer._id,
        email: customer.email,
        name: customer.email,
    };
};
